import React, { useEffect, useState } from "react";
import Modal from "./Modal"; // Import the Modal component
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { jobDetail1, jobDetail2, listIcon, service3 } from "../../../assets";
import { formatDistanceToNow, parse } from "date-fns";
import { useTranslation } from 'react-i18next';

export default function JobsDetail() {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [selectedQuantity, setSelectedQuantity] = useState(1);  
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientAddress, setclientAddress] = useState("");


    const { t, i18n } = useTranslation();
 

  const { id } = useParams();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.gpr.az/gpr/jobs.php`,
        { withCredentials: false }
      );
  
        const filteredProducts = response.data.data.filter(product => product.id == id);

        setItems(filteredProducts[0]);
        setIsLoading(false)
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  function goToApplication() {
    navigate(`/career/job-detail/${id}/apply`)
  }
  

    const genderText = list.gender === 'men' 
    ? 'Kişi' 
    : list.gender === 'women' 
    ? 'Qadın' 
    : list.gender === 'unisex' 
    ? 'Unisex' 
    : '';

  return (
   <>
   {console.log(list)}
    <div className="max-w-containerSm md:max-w-container mx-auto text-[white] pt-[119px]">
      <div className="md:flex justify-between pb-[36px]">
        <div className="md:w-[980px]">
          <h4 className="text-[22px] md:text-[40px] font-bold mb-2">{list.job}</h4>
          <div className='flex mt-2 text-[#BABABA] md:w-[385px]'>
                   <div className='flex items-center'>
                    <img className='h-4 w-4 mr-[6px]' src={jobDetail1}></img>
                   <div>
                   <p className="text-left text-[16px] md:text-[16px]">
                   {list.category === "youth" ? t("Təcrübəçi") : list.category === "experienced" ? t("Təcrübəli kadr") : list.category}
          </p>
                   </div>
                   </div>
                   <div className='flex items-center h-4 rounded-full mx-6'>
                  <p className='rounded-full'>.</p>
                   </div>
          
          <div className='flex items-center'>
            <img className='h-4 w-4 mr-[6px]' src={jobDetail2}></img>
          <p className="text-left text-[16px] md:text-[16px]">  
          {isLoading ? "Yükleniyor..." : 
    (list.createdTime ? formatDistanceToNow(
      parse(list.createdTime.replace(" ", "T"), "yyyy-MM-dd'T'HH:mm:ss", new Date()), 
      { addSuffix: true }
    ) : "Tarih bilgisi yok")}
          </p>
          </div>
                    </div>
          <p className="mb-4 text-[white] mt-5 md:mt-8">{list.description}</p>
          <p className="mb-4  font-semibold text-[white]">{t("Vəzifə səlahiyyətləri və məsuliyyətlər:")}</p>
          <div className='flex items-center'>
                       <img className='h-3 w-3' src={listIcon}></img>
                       <p className='text-[#FBFBFB] ml-3'>{list.responsibilities}</p>
                     </div>
                     <div className='flex items-center'>
                       <img className='h-3 w-3' src={listIcon}></img>
                       <p className='text-[#FBFBFB] ml-3'>{list.responsibilities}</p>
                     </div>
                     <div className='flex items-center'>
                       <img className='h-3 w-3' src={listIcon}></img>
                       <p className='text-[#FBFBFB] ml-3'>{list.responsibilities}</p>
                     </div>
          <p className="mb-4 font-semibold text-[white] mt-4">Vakansiya ilə bağlı tələblər:</p>
          <div className='flex items-center'>
                       <img className='h-3 w-3' src={listIcon}></img>
                       <p className='text-[#FBFBFB] ml-3'>{list.requirements}</p>
                     </div>
                     <div className='flex items-center'>
                       <img className='h-3 w-3' src={listIcon}></img>
                       <p className='text-[#FBFBFB] ml-3'>{list.requirements}</p>
                     </div>
                     <div className='flex items-center'>
                       <img className='h-3 w-3' src={listIcon}></img>
                       <p className='text-[#FBFBFB] ml-3'>{list.requirements}</p>
                     </div>
          <p className="mb-4  font-semibold text-[white] mt-4">{t("Biz təklif edirik:")}</p>
          <div className='flex items-center'>
                       <img className='h-3 w-3' src={listIcon}></img>
                       <p className='text-[#FBFBFB] ml-3'>{list.offers}</p>
                     </div>
                     <div className='flex items-center'>
                       <img className='h-3 w-3' src={listIcon}></img>
                       <p className='text-[#FBFBFB] ml-3'>{list.offers}</p>
                     </div>
                     <div className='flex items-center'>
                       <img className='h-3 w-3' src={listIcon}></img>
                       <p className='text-[#FBFBFB] ml-3'>{list.offers}</p>
                     </div>

          <div className="flex mt-6">
            <button 
             onClick={goToApplication} // Open modal on button click
              className="mt-4 px-4 py-2 bg-primeColor text-white rounded-[16px] w-[170px]"
            >
              {t("Müraciət et")}
            </button>
          </div>
        </div>
      </div>
    </div>
    {/* Modal */}

    </>
  );
} 