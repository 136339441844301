import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { formatDistanceToNow } from "date-fns";
import { jobDetail1, jobDetail2 } from '../../../assets';
import { useTranslation } from 'react-i18next';

// Example items, to simulate fetching from another resource.
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function Items({ currentItems }) {
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch("https://www.kcollection.az/kcollection/dashboard.php/", {
        credentials: 'omit'
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setItems(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='grid grid-cols-3 gap-4'>
        {currentItems && currentItems.map((item, index) => (
          <div className='bg-[green]' key={index}>
            <h3>Name #{item}</h3>
          </div>
        ))}
      </div>
    </>
  );
}

function PaginatedItems({ itemsPerPage, items }) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);


  const { t, i18n } = useTranslation();

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };



  return (
    <>
     <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
  {currentItems && currentItems.map((item, index) => (
    <div className='bg-[#1C1C1C] rounded-[16px] md:w-[748px] min-h-[166px] mx-auto md:mx-0' key={index}>
      <a href={`job-detail/${item.id}`}>
        <div className='p-6 text-[white]'>
          <p className='text-left text-[16px] md:text-[24px] font-medium'> {item.job}</p>
          <div className='flex mt-2 text-[#BABABA] md:w-[388px]'>
         <div className='flex items-center'>
          <img className='h-4 w-4 mr-[6px]' src={jobDetail1}></img>
         <div>
         <p className="text-left text-[16px] md:text-[16px]">
  {item.category === "youth" ? t("Təcrübəçi") : item.category === "experienced" ? t("Təcrübəli kadr") : item.category}
</p>
         </div>
         </div>
         <div className='flex items-center h-4 rounded-full mx-6'>
        <p className='rounded-full'>.</p>
         </div>

<div className='flex items-center'>
  <img className='h-4 w-4 mr-[6px]' src={jobDetail2}></img>
<p className="text-left text-[16px] md:text-[16px]">  
  {formatDistanceToNow(new Date(item.createdTime), { addSuffix: true })}
</p>
</div>
          </div>
          <p className='text-left text-[16px] md:text-[16px] mt-3'>  {item.description}</p>
        </div>
      </a>
    </div>
  ))}
</div>


<ReactPaginate
  nextLabel="sonrakı"
  onPageChange={handlePageClick}
  pageRangeDisplayed={3}
  pageCount={pageCount}
  previousLabel="əvvəlki"
  renderOnZeroPageCount={null}
  containerClassName="flex justify-center items-center space-x-1 my-4"
  pageClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
  previousClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
  nextClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
  activeClassName="bg-blue-600 text-white"
  disabledClassName="text-gray-400 cursor-not-allowed"
/>

    </>
  );
}

export default PaginatedItems;