import React, { useState } from 'react';
import { about, aboutSosial, biology, careerJoin, mission, service1 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';




const faqs = [
  {
    question: "Şirkət haqqında ümumi məlumat və məqsədimiz",
    answer: "GPR Şirkəti 20 ildən artıq təcrübə ilə sizlərə ictimaiyyətlə əlaqələr, ənənəvi və rəqəmsal marketinq, qrafik dizayn, media, reklam, çap və poliqrafiya, PR təlimi və tədbirlərin təşkili sahələri üzrə peşəkar xidmətlər təklif edir. Məqsədimiz şirkət və ya brendinizin cəmiyyətdə tanınması, nüfuzun formalaşdırılması, PR və Marketinq siyasətinin uğurla həyata keçirilməsi nəticəsində biznesinizin inkişafına nail olmaqdır.",
  },
  {
    question: "GPR hansı sahələrdə xidmətlər göstərir?", 
    answer: "GPR ictimaiyyətlə əlaqələr, ənənəvi və rəqəmsal marketinq, brendinq, media, çap və poliqrafiya, tədbirlərin təşkili, PR və kommunikasiya təlimi, netvörkinq, reklam və qrafik dizayn sahələri üzrə fəaliyyət göstərir.",
  },
  {
    question: "Şirkətin dəyərləri hansılardır?",
    answer: "Peşəkarlıq, məsuliyyətlilik, innovasiya və inkişaf, müştəri yönümlülük, empatiya və etik davranış.",
  },
  {
    question: "Şirkətdaxili karyera inkişafı imkanları varmı?",
    answer: "Şirkətdaxili karyera inkişafı müasir iş mühitində həm işəgötürən, həm də işçilər üçün böyük əhəmiyyət kəsb edir. İşçilərin potensialını üzə çıxarmaq və onları uzunmüddətli əməkdaşlığa təşviq etmək üçün GPR bu sahədə müxtəlif strategiyalar tətbiq edir. Belə ki, şirkətdə yeni vəzifələr yarananda əvvəlcə mövcud əməkdaşlara müraciət edilir, bu da işçilərin yüksəlmə şansını artırır. Əməkdaşların bilik və bacarıqlarını artırmaq üçün müntəzəm olaraq təşkil edilən seminarlar, kurslar və sertifikat proqramları onların vəzifələrini daha effektiv icra etməsinə və daha yüksək mövqelərə hazır olmasına kömək edir.",
  },
  {
    question: "Şirkətdə iş mühiti necədir?",
    answer: "GPR-da iş mühiti yalnız fiziki iş şəraiti ilə məhdudlaşmır; o həm də təşkilatın mədəniyyəti, idarəetmə üslubu və işçilər arasında olan münasibətləri əhatə edir. Buraya yaradıcı professional komanda, sağlam ünsiyyət, dəstək, motivasiya, pozitiv şirkət mədəniyyəti, yaradıcı komanda, dürüstlük, səmimiyyət və hörmət kimi dəyərlər, ədalətli idarəetmə, rəhbərliyin dəstəkləyici və ilhamvericiliyi, iş-həyat balansı və sərbəstlik kimi elementlər aiddir.",
  },
];

const CareerJoin = () => {


  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
     <div className='pt-[79px]'>
    <div
                className="relative h-[203px] md:h-[339px]"
                style={{
                  backgroundImage: `url(${careerJoin})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                {/* Metin kutusu */}
                <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
                  <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold ">
                  {t("Karyera hekayəniz GPR-la başlasın!")}
                  </div>
                </div>
              </div>


     <div className='bg-[black] md:py-[86px] py-[32px]'>
     <div className="max-w-containerSm md:max-w-container mx-auto">
        <div className='max-w-[966px]'>
          <p className='text-[#FBFBFB]'><span className='font-semibold'>{t("Dəyərli namizəd,")}</span> {t("karyera hekayənizin əsas qəhrəmanı sizsiniz. Biz isə bu hekayənin parlaq olması üçün sizə ilham verən və dəstək göstərən tərəfdaş olmaqdan qürur duyuruq.")} <br></br><br></br>


{t("Hər bir uğurun başlanğıc nöqtəsi var. Sizin karyera hekayənizin də ilk səhifəsini bizimlə yazmağa dəvət edirik. Hər bir addımınızda dəstək göstərən, öyrənmək və inkişaf etmək üçün geniş imkanlar təqdim edən bir iş mühiti arzulayırsınızsa, doğru ünvandasınız!")} <br></br><br></br>

<div dangerouslySetInnerHTML={{ __html: t("join1") }} /><br></br>

<div dangerouslySetInnerHTML={{ __html: t("join2") }} /><br></br>

<div dangerouslySetInnerHTML={{ __html: t("join3") }} /><br></br>

<div dangerouslySetInnerHTML={{ __html: t("join4") }} /><br></br>

</p>
        </div>
      </div>
     </div>
     <div className='bg-[#1C1C1C]  py-[40px] md:py-[60px]'>
     <div className="max-w-containerSm md:max-w-container mx-auto">
        <div className='max-w-[1036px]'>
          <p className='font-semibold '>
          <span className="text2-gradient">{t("Peşəkarlar üçün")}</span> 
          </p>
        <p className='text-[#FBFBFB] text-[24px] md:text-[40px] font-bold'>{t("Gəlin, birlikdə")} <span className='text2-gradient'>{t("gələcək karyeranızı")}</span> {t("formalaşdıraq!")}</p>
        <p className='mt-5 md:mt-8 text-[#FBFBFB] '>{t("Karyeranızı inkişaf etdirmək üçün düzgün tərəfdaş seçmək vacibdir. GPR karyeranızla bağlı xəyallarınızı gerçəkləşdirmək üçün doğru ünvandır!")}</p>
        <a href='/career/join/professionals'>
            <button className='text-[white] bg-primeColor rounded-[20px] w-[148px] h-[40px] flex items-center justify-center mt-6'>
            {t("Daha ətraflı")}
            </button>
          </a>
        </div>
      </div>
     </div>
     <div className='bg-[black] md:py-[86px] py-[32px]'>
     <div className="max-w-containerSm md:max-w-container mx-auto ">
        <div className='max-w-[1035px]'>
          <p className='text-[#FBFBFB] text-[24px] font-bold md:text-[40px]'>{t("Bizimlə işləmək nə üçün doğru seçimdir?")}</p>
        <p className='text-[#FBFBFB] md:mt-[32px] mt-[20px]'>{t("GPR təkcə iş yeri deyil, komanda üzvlərini uğura aparan, şəxsi və peşəkar inkişaflarını dəstəkləyən professionalların toplandığı yaradıcı mühitdir. Komandamızla birgə çalışmağa qərar verdiyiniz təqdirdə, siz karyera yolunuzda sonsuz uğurlar əldə edəcəksiniz. PR və marketinq sahəsində GPR-da qazandığınız təcrübə fəaliyyətinizdə sizlər üçün çox yararlı olacaq. Uğura doğru GPR-la birlikdə addımlayın!")}</p>
        </div>
      </div>
     </div>
     <div className='bg-[#1C1C1C]  py-[40px] md:py-[60px]'>
     <div className="max-w-containerSm md:max-w-container mx-auto">
        <div className='max-w-[1036px]'>
            <p className='font-semibold '>
          <span className="text2-gradient">{t("Təcrübəçilər üçün")}</span> 
          </p>
        <p className='text-[#FBFBFB] text-[24px] md:text-[40px] font-bold'> <span className='text2-gradient'>{t("Yaxşı başlanğıc")}</span> {t("uğurlu yolun yarısıdır!")}</p>
        <p className='mt-5 md:mt-8 text-[#FBFBFB] '>{t("Dəyərli namizəd, əgər özünüzü bizimlə birlikdə inkişaf edən gələcəyin bir hissəsi kimi görürsünüzsə, bu gün müraciət edin!")} <br></br>
{t("Seçiminiz nə olursa olsun, sizlərə gələcək karyeranızda uğurlar arzulayırıq!")} <br></br>
{t("İnanırıq ki, GPR-ın təqdim etdiyi sahələr üzrə yolumuz yenidən kəsişəcəkdir!")}</p>
        <a href='/career/join/youths'>
            <button className='text-[white] bg-primeColor rounded-[20px] w-[148px] h-[40px] flex items-center justify-center mt-6'>
            {t("Daha ətraflı")}
            </button>
          </a>
        </div>
      </div>
     </div>
     <div className="max-w-containerSm md:max-w-container mt-[64px] md:mt-[86px] mx-auto">
          <p className="text-white text-[24px] md:text-[40px] font-bold">{t("Tez-tez soruşulan suallar")}</p>
        <div className="space-y-8 mt-[40px] md:mt-[60px]">
    {faqs.map((faq, index) => (
      <div 
        key={index}
        className={`rounded-[18px] overflow-hidden px-4 md:px-10 py-3 bg-white shadow ${
          activeIndex === index ? ' bg-primeColor' : ''
        }`}
      >
        <div
          onClick={() => toggleFAQ(index)}
          className="flex justify-between items-center cursor-pointer group"
        >
          <h2
            className={`text-[14px] md:text-[20px] font-bold ${
              activeIndex === index ? 'text-white' : 'text-[black]'
            }`}
          >
            {t(faq.question)}
          </h2>
          <div
            className={`w-[32px] h-[32px] rounded-[50%] ${
              activeIndex === index ? 'border border-[#FFF]' : 'bg-[white]'
            } flex justify-center items-center border-[black] border `}
          >
            <i
              className={`${
                activeIndex === index
                  ? 'fa-solid fa-arrow-up text-[white]'
                  : 'fa-solid fa-arrow-down text-[black]'
              }`}
            />
          </div>
        </div>
        {activeIndex === index && (
          <div className="pt-4">
            <p className="text-[#FBFBFB] text-[14px] md:text-[16px] md:w-[1100px]">{t(faq.answer)}</p>
          </div>
        )}
      </div>
    ))}
  </div>
    </div>
     </div>
    </>
  );
};

export default CareerJoin;

