import { useTranslation } from "react-i18next";

const ContactUs = () => {
  

  const { t, i18n } = useTranslation();
  return (
    <div className='max-w-containerSm md:max-w-container mx-auto mt-[94px] md:mt-[80px] relative'>
      <div className='absolute inset-0 bg-gradiet-1 blur-300px'></div>
      <div className='md:flex gap-8 text-white relative'>
        <div className='flex flex-col justify-center mt-5 md:mt-0'>
          <p className='text-[28px] md:text-[32px] font-medium'>
          {t("PR və Marketinqdə doğru addımlarla brendinizi gələcəyə aparırıq!")}
          </p>
          <p className='md:mt-[16px] mt-[20px] text-[16px] md:text-[20px] text-[#A7A7A7]'>{t("Bizimlə əlaqə saxlayın və yeni imkanlara qapı açın.")}</p>
          <a href='/contact'>
          <button className="gradient-border-button mt-[20px] md:mt-[24px]">
          {t("Əlaqə")}
    </button>
          </a>
        </div>
      </div>
    </div>
  );
}; 

export default ContactUs;







