import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service2, service7, services2 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const Pr = () => {

     const { t, i18n } = useTranslation();
  return (
    <>
     <div className='pt-[79px]'>

<div
  className="relative h-[203px] md:h-[339px]"
  style={{
    backgroundImage: `url(${services2})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  {/* Metin kutusu */}
  <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
    <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
    {t("PR xidməti")}
    </div>
  </div>
</div>


    <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
            <div>
            <p className='text-[#FBFBFB] font-medium'>{t("PR xidməti (ictimayyətlə əlaqələr) vasitəsilə şirkətinizin media və mətbuatla kommunikasiyalarını idarə edirik. Məqsədimiz ictimaiyyət, potensial müştərilər və digər maraqlı tərəflər (tərəfdaşlar, investorlar və rəqiblər qarşısında müsbət brend imicinizi yaratmaq və saxlamaqdır. Bu xidmət yalnız şirkətinizin və ya brendinizin tanıtımını deyil, həm də onun davamlı ictimai nüfuzunun təmin edilməsi məqsədini özündə ehtiva edir.")}</p>
            <div className='flex mt-2 mt-8 '>
              <img className='h-8 w-8' src={service1}></img>
              <p className='text-[#FBFBFB] ml-3'>{t("PR xidmətinə daxildir:")}</p>
            </div>
            <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
              <div className='flex '> 
                <div className='flex mt-2 mr-2'>
                <img className='w-3 h-3' src={listIcon}></img>
                </div>
                <p className='flex-1'>{t("Şirkətiniz və ya brendiniz haqqında müxtəlif auditoriyalara uyğun uzunmüddətli və qısamüddətli mesajların formalaşdırılması;")}</p>
              </div>
              <div className='flex'> 
                <div className='flex mt-2 mr-2'>
                <img className='w-3 h-3' src={listIcon}></img>
                </div>
                <p className='flex-1'>{t("Şirkətiniz və ya brendiniz haqqında məqalələrin və press relizlərin hazırlanması;")}</p>
              </div>
              <div className='flex'>
                <div className='flex mt-2 mr-2'>
                <img className='w-3 h-3' src={listIcon}></img>
                </div>
                <p className='flex-1'>{t("Media və mətbuat üçün müsahibə mətnlərinin hazırlanması;")}</p>
              </div>
              <div className='flex'>
                <div className='flex mt-2 mr-2'>
                <img className='w-3 h-3' src={listIcon}></img>
                </div>
                <p className='flex-1'>{t("Şirkət və ya brendiniz haqqında yayımlanan xəbərlərin izlənməsi və təhlili.")}</p>
              </div>
    
    </ul>
    
            </div>
          </div>
     </div>
    </>
  );
};

export default Pr;