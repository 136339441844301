import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service3, service4, services4 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const Branding = () => {

     const { t, i18n } = useTranslation();
     
  return (
    <>
     <div className='pt-[79px]'>
    <div
      className="relative h-[203px] md:h-[339px]"
      style={{
        backgroundImage: `url(${services4})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Metin kutusu */}
      <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
        <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
        {t("Brendinq")}
        </div>
      </div>
    </div>
 

             <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
                     <div>
                     <p className='text-[#FBFBFB] font-medium'>{t("Brendinq xidməti vasitəsilə şirkət və brendinizin unikallığını formalaşdırır, müştərilərin yaddaşında qalıcı imicinizi yaradır, Sizi bazar rəqiblərindən fərqləndiririk. Bu xidmətin əsas məqsədi müştərilərin şirkət və ya brendinizi tanıması, ona güvənməsi və üstünlük verməsidir.")}</p>
                     <div className='flex items-center mt-8'>
                       <img className='h-8 w-8' src={service3}></img>
                       <p className='text-[#FBFBFB] ml-3'>{t("Brendinq xidmətinə daxildir:")}</p>
                     </div>
                     <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                       <div className='flex'>
                         <div className='flex mt-2 mr-2'>
                         <img className='w-3 h-3' src={listIcon}></img>
                         </div>
                         <p className='flex-1'>{t("Şirkət və ya brendinizin missiyası, vizyonu və dəyərləri müəyyən edilir;")}</p>
                       </div>
                       <div className='flex'>
                         <div className='flex mt-2 mr-2'>
                         <img className='w-3 h-3' src={listIcon}></img>
                         </div>
                         <p className='flex-1'>{t("Hədəf auditoriyası seçilir və onlara nüfuz alətləri araşdırılır;")}</p>
                       </div>
                       <div className='flex'>
                         <div className='flex mt-2 mr-2'>
                         <img className='w-3 h-3' src={listIcon}></img>
                         </div>
                         <p className='flex-1'>{t("Rəqiblərin analizi və fərqlənmə yolları aşkarlanır;")}</p>
                       </div>
                       <div className='flex'>
                         <div className='flex mt-2 mr-2'>
                         <img className='w-3 h-3' src={listIcon}></img>
                         </div>
                         <p className='flex-1'>{t("Şirkət adı, loqosu, şüarı və sair üzərində təkmilləşmələr aparılır;")}</p>
                       </div>
                       <div className='flex'>
                         <div className='flex mt-2 mr-2'>
                         <img className='w-3 h-3' src={listIcon}></img>
                         </div>
                         <p className='flex-1'>{t("Şirkət və ya brendinizin üçün “Brand Book” hazırlanır, spesifik detallar – loqo, rəng palitrası, şriftlər, ikonlar və digər dizayn elementləri qeyd edilir;")}</p>
                       </div>
                       <div className='flex'>
                         <div className='flex mt-2 mr-2'>
                         <img className='w-3 h-3' src={listIcon}></img>
                         </div>
                         <p className='flex-1'>{t("Şirkət və ya brendinizin vizual imicini gücləndirən qrafik elementlər hazırlanır;")}</p>
                       </div>
                       <div className='flex'>
                         <div className='flex mt-2 mr-2'>
                         <img className='w-3 h-3' src={listIcon}></img>
                         </div>
                         <p className='flex-1'>{t("Şirkət və ya brendinizin hekayəsini və dəyərlərini ifadə edən konseptlər təkmilləşdirilir.")}</p>
                       </div>
             
             </ul>
             
                     </div>
                   </div>
     </div>
    </>
  );
};

export default Branding;

