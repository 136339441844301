import React from 'react';
import { about, aboutSosial, biology, careerProfessionals, mission, service1 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const CareerJoinProfessionals = () => {

  const { t, i18n } = useTranslation();


  return (
    <>
     <div className='pt-[79px]'>
     <div
                    className="relative h-[203px] md:h-[339px]"
                    style={{
                      backgroundImage: `url(${careerProfessionals})`,
                      backgroundSize: 'cover', 
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    {/* Metin kutusu */}
                    <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
                      <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold ">
                      {t("Peşəkarlar üçün karyera imkanları")}
                      </div>
                    </div>
                  </div> 


      <div className="max-w-containerSm md:max-w-container mx-auto md:mt-[40px] mt-[32px]">
        <div className='max-w-[1010px]'>
          <p className='text-[#FBFBFB] font-bold text-[24px] md:text-[40px]'>{t("Peşəkar karyeranızı bizimlə formalaşdırın!")}</p>
        <p className='text-[#FBFBFB] md:mt-[32px] mt-[20px]'>{t("Şirkətdaxili karyera inkişafı müasir iş mühitində həm işəgötürən, həm də işçilər üçün böyük əhəmiyyət kəsb edir. İşçilərin potensialını üzə çıxarmaq və onları uzunmüddətli əməkdaşlığa təşviq etmək üçün GPR müxtəlif strategiyalar tətbiq edir.")} <br></br><br></br>

{t("Şirkət daxilində yeni vəzifələr yarananda əvvəlcə mövcud əməkdaşlara müraciət edilir, bu da işçilərin yüksəlmə şansını artırır, onların bacarıqlarına olan inamı möhkəmləndirir.")} <br></br><br></br>

{t("Əməkdaşların bilik və bacarıqlarını artırmaq üçün müntəzəm olaraq təşkil edilən seminarlar, kurslar, təlimlər və sertifikat proqramları onların vəzifələrini daha effektiv icra etməsinə və daha yüksək mövqelərə hazır olmasına kömək edir.")} <br></br><br></br>

{t("Şirkətdə ədalətli qiymətləndirmə sistemləri vasitəsilə əməkdaşların performansı izlənilir və mükafatlandırılır, yüksəlişlər konkret nailiyyətlərə əsaslanır.")} <br></br><br></br>

{t("Karyera imkanları işçilərdə şirkətə qarşı bağlılıq və loyallığı artırır. Onlar özlərini şirkətin ayrılmaz bir hissəsi kimi hiss edirlər. Müxtəlif şöbələrdə çalışmaq imkanı işçilərin müxtəlif sahələrdə təcrübə qazanmasına və daha geniş perspektiv əldə etməsinə şərait yaradır.")} <br></br><br></br>
{t("GPR-dakı iş mühiti şirkətin uğurunda əsas faktorlardan biridir. İşçilərin özlərini rahat, dəyərli və motivasiyalı hiss etmələri onların məhsuldarlığını, yaradıcılığını və şirkətə uzunmüddətli bağlılığını təmin edir. Buradakı iş mühiti yalnız fiziki iş şəraiti ilə məhdudlaşmır, o həm də təşkilatın mədəniyyəti, idarəetmə üslubu və işçilər arasında olan münasibətləri əhatə edir, sağlam ünsiyyət, dəstək, motivasiya və rahatlıq kimi elementləri özündə birləşdirir.")}</p>
<a href='/career/jobs'>
            <button className='text-[white] bg-primeColor rounded-[20px] w-[148px] h-[40px] flex items-center justify-center mt-6'>
            {t("Müraciət et")}
            </button>
          </a>
        </div>
      </div>
     </div>
    </>
  );
};

export default CareerJoinProfessionals;

