

import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { contact, contactBg } from "../../../assets/index";


const Contact = () => {

  const { t, i18n } = useTranslation(); 
  return (
    <>
   <div className="md:mt-[100px] mt-[40px]">
     <div className='md:mb-[40px] mb-[20px] text-center'>
       <div className="flex justify-center items-center mx-auto">
  {/* Dekoratif Duvar */}
  <div className="w-[2px] h-[20px] bg-[#E0B497] mb-4 mt-4"></div>
  {/* Metin */}
  <p className="text-[16px] font-medium text-center text-[#E0B497] ml-[5px]">
  {t("Əlaqə")}
  </p>
</div>

      <p className='text-[28px] md:text-[40px]  font-bold text-center '>
          <span className="text2-gradient">{t("Bizə yaza bilərsiniz")}</span> 
          </p>
     </div>
   </div>
      <div>
    <div className='mx-auto md:mt-[60px] mb-[40px] md:mb-[160px] max-w-containerSm md:max-w-[1260px]'>
 <div className='md:flex mb-[40px] justify-between'>
<div className='md:w-[55%]'>
<form class="space-y-8">
      <div>
        <label for="company" class="block text-base font-medium text-[white]">{t("Ad, soyad")}*</label>
        <input type="text" id="company" class="bg-[#292828] text-[white] mt-2 block w-full pl-3 pr-3 h-10 rounded-xl shadow-sm focus:outline-none sm:text-sm " ></input>
      </div>

    
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6"> 
    <div>
        <label for="phone" class="block text-base font-medium text-[white]">{t("Əlaqə nömrəsi")}*</label>
        <input type="number" id="phone" class="bg-[#292828] mt-2 text-[white] block w-full pl-3 pr-3 h-10 rounded-xl shadow-sm focus:outline-none sm:text-sm " ></input>
      </div>
    <div>
        <label for="email" class="block text-base font-medium text-[white]">{t("E-mail")}</label>
        <input type="email" id="email" class="bg-[#292828] mt-2 text-[white] block w-full pl-3 pr-3 h-10 rounded-xl shadow-sm focus:outline-none  sm:text-sm " ></input>
      </div>
    </div>

    <div>
      <label for="message" class="block text-base font-medium text-[white]">{t("Mesajınız")}*</label>
      <textarea placeholder={t("Bizə yazın...")} id="message" rows="4" class="bg-[#292828] h-36 text-[white] mt-2 pl-3 pr-3 pt-3 block w-full rounded-xl shadow-sm focus:outline-none sm:text-sm" ></textarea>
    </div>

    <div class="flex justify-start">
      <button type="submit" class="w-[148px] h-10 p-2.5 bg-primeColor rounded-xl justify-center items-center gap-2.5 inline-flex text-[white]">{t("GÖNDƏR")}</button>
    </div>
  </form>
 </div>
 <div className='md:w-[37%] flex flex-col justify-between mt-4 md:mt-0'>
 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194473.18588939894!2d49.8549596!3d40.394592499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d6bd6211cf9%3A0x343f6b5e7ae56c6b!2zQmFrxLE!5e0!3m2!1saz!2saz!4v1733142028974!5m2!1saz!2saz" width="100%" height="445" style={{borderRadius:'8px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div> 
 </div>
    </div>
  </div>
    </>

  );
};

export default Contact;