import React from 'react';
import { about, aboutSosial, biology, biomuxteliflik, mission, service1, service2, service3, service4, sosialServices1, sosialServices2, sosialServices3, sosialServices4 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const AboutSosial = () => {

    const { t, i18n } = useTranslation();


  return (
    <>
     <div className='pt-[79px]'>
     <div
  className="relative h-[203px] md:h-[339px]"
  style={{
    backgroundImage: `url(${aboutSosial})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  {/* Metin kutusu */}
  <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
    <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold">
    {t("Sosial layihələr")}
    </div>
  </div>
</div>


      <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[80px]">
        <div className='md:flex justify-between'>
      <div className='md:w-[391px]'>
<p className='text-[24px] font-bold md:text-[48px] md:font-extrabold text-white'>{t("Biomüxtəliflik")}</p>
<img className='hidden md:block' src={biomuxteliflik}></img>
      </div>
      <div className='md:w-[688px] mt-[40px] md:mt-0'>
      <div className="flex">
  <img className="w-[60px] h-[60px]" src={sosialServices1}></img>
  <div className="ml-5">
    <p className="text-[white] font-semibold text-[18px] md:text-[20px]">{t("Təkrar emal")}</p> 
    <p className="text-[#FBFBFB] mt-3 md:mt-5 text-[14px] md:text-[16px]">{t("Bu prosesin əsas məqsədi təbii sərvətlərin həddindən artıq istifadəsini əngəlləmək və ətraf mühitin çirklənməsinin qarşısını almaqdır.")}</p>
          <a href='/sosial/recycling'>
          <button className="gradient-border-button2 mt-3 md:mt-5">
          {t("Daha çox")}
    </button>
          </a>
          </div>
  </div>
  <div className="flex mt-[24px] md:mt-[44px] ">
  <img className="w-[60px] h-[60px]" src={sosialServices2}></img>
  <div className="ml-5">
    <p className="text-[white] font-semibold text-[18px] md:text-[20px]">{t("Ekosistemin qorunması")}</p> 
    <p className="text-[#FBFBFB] mt-3 md:mt-5 text-[14px] md:text-[16px]">{t("Ekosistemin qorunması isə həm ətraf mühitin sağlamlığı, həm də insan həyatının davamlılığı üçün vacibdir.")}</p>
    <a href='/sosial/ecosystem'>
          <button className="gradient-border-button2 mt-3 md:mt-5">
          {t("Daha çox")}
    </button>
          </a>
          </div>
  </div>
  <div className="flex mt-[24px] md:mt-[44px] ">
  <img className="w-[60px] h-[60px]" src={sosialServices3}></img>
  <div className="ml-5">
    <p className="text-[white] font-semibold text-[18px] md:text-[20px]">{t("Canlılar aləmi")}</p> 
    <p className="text-[#FBFBFB] mt-3 md:mt-5 text-[14px] md:text-[16px]">{t("Hər bir heyvan növü təbiətin tarazlığında xüsusi rol oynayır və ekosistemin sağlamlığı üçün əvəzolunmazdır.")}</p>
    <a href='/sosial/organic'>
          <button className="gradient-border-button2 mt-3 md:mt-5">
          {t("Daha çox")}
    </button>
          </a>
          </div>
  </div>
        </div>
        </div>
        <div className='mt-[40px] md:mt-[80px] md:flex justify-between'>
      <div className='md:w-[550px]'>
        <p className='text-[28px] md:text-[40px]  font-bold mt-5 '>
          <span className="text2-gradient">{t("Bizim Missiyamız")}</span> 
          </p>
        <p className='text-[#FBFBFB] text-[14px] md:text-[16px] mt-[28px] font-bold text-justify'>{t("Biomüxtəlifliyi qorumaq üçün hər bir fərdin, şirkətin və dövlətin üzərinə mühüm məsuliyyət düşür. Bizim missiyamız təbiətin bu zənginliyini qoruyaraq gələcək nəsillərə ötürməkdir. GPR biomütəlifliyin qorunması məsuliyyətini başa düşür və hamını biomüxtəlifliyi qorumağa təşviq edən layihələrdə iştiraka və dəstək olmağa çağırır. Çünki biomüxtəliflik insan həyatının və təbiətin davamlılığını təmin edən əsas amillərdən biridir. Onu qorumaq, yalnız alimlərin, ekoloqların və dövlətlərin deyil, hər birimizin məsuliyyətidir. Gələcək nəsillərə sağlam ekosistemlər və zəngin təbii sərvətlər qoymaq üçün bu gün birlikdə hərəkət etməliyik. Bu bizim təbiətə olan borcumuzdur və eyni zamanda ən böyük missiyamızdır!")}</p>
      </div>
      <div className='md:w-[603px] mt-10 md:mt-0'>
        <img src={mission}></img> 
        </div>
        </div>
      </div>
     </div>
    </>
  );
};

export default AboutSosial;
