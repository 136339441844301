import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { contact } from '../../../assets';

const Awards = () => {
  const { t } = useTranslation();
  const [inView, setInView] = useState(false);
  const awardsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect(); // Animasyon bir kez başladığında daha fazla gözlemleme gerek yok
        }
      },
      { threshold: 0.3 } // Komponentin %30'u görünür hale geldiğinde başlar
    );

    if (awardsRef.current) {
      observer.observe(awardsRef.current);
    }

    return () => {
      if (awardsRef.current) {
        observer.unobserve(awardsRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={awardsRef}
      style={{
        backgroundImage: `url(${contact})`,
        backgroundSize: 'cover', // Görüntüyü kapsayıcıya yayar
        backgroundPosition: 'center', // Görüntüyü ortalar
        backgroundRepeat: 'no-repeat', // Görüntünün tekrarlanmamasını sağlar
        height: window.innerWidth <= 640 ? '200px' : '360px',
      }}
      className="bg-[#0C223E] text-white text-center flex flex-col justify-center"
    >
      <div className="max-w-containerSm md:max-w-container mx-auto md:flex w-full ">
        <p className="md:w-[644px] text-left mr-[14px] font-semibold text-[18px] md:text-[32px] flex  items-center">
        {t("Effektiv həllərlə")} <br></br> {t("etibarlı tərəfdaşlıq")}
        </p>
       <div className='flex mt-6 md:mt-0'>
       <div className="text-left w-[134px] ">
          <p className='text-[16px] md:text-[32px] font-semibold'>20+</p> <br />
          <p className='text-[16px] md:text-[18px]'>{t("İllik təcrübə")}</p>
        </div>
       <div className='flex justify-center items-center mx-[28px]'>
       <div className='bg-[white] h-[32px] w-[2px] '></div>
       </div>
       <div className="text-left w-[159px] ">
          <p className='text-[16px] md:text-[32px] font-semibold'>135+</p> <br />
          <p className='text-[16px] md:text-[18px]'>{t("Məmnun müştəri")}</p>
        </div>
        <div className='flex justify-center items-center mx-[28px]'>
       <div className='bg-[white] h-[32px] w-[2px] '></div>
       </div>
       <div className="text-left w-[121px] ">
          <p className='text-[16px] md:text-[32px] font-semibold'>110+</p> <br />
          <p className='text-[16px] md:text-[18px]'>{t("Uğurlu layihə")}</p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;

