import React from 'react';
import { about, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, carousel2, dataTerminal, hero, pulQutulari, blogs1, blogs2, blogs3, project1, project2, project3, project4, project5, project6, rightArrow, gLeft, gRight, logo, service1, service2, service3, service4, service6, service5, service8, service7, serviceWhite1, serviceWhite2, serviceWhite3, serviceWhite4, serviceWhite5, serviceWhite6, serviceWhite7, serviceWhite8, service10, serviceWhite10, service9, serviceWhite9 } from '../../assets/index';
import { useTranslation } from 'react-i18next';

const Services = () => {

    const { t, i18n } = useTranslation();

  return (
   <>
   <div className='pt-[79px]'>
    
   <div className='mx-auto mt-[20px] md:mt-[52px] relative '> 
      <div className="flex justify-center items-center mx-auto max-w-containerSm md:max-w-container">
  {/* Dekoratif Duvar */}
  <div className="w-[2px] h-[20px] bg-[#E0B497] mb-4 mt-4"></div>
  {/* Metin */}
  <p className="text-[16px] font-semibold text-center text-[#E0B497] ml-[5px]">
  {t("Xidmətlər")}
  </p>
</div>
 
      <p className='text-[28px] md:text-[40px]  font-bold text-center '>
          <span className="text2-gradient">{t("Brendinizi Yüksəldən Xidmətlər")}</span> 
          </p>
      {/* Arka plandaki fotoğraflar */}
      <div className="absolute top-0 right-0 w-[467px] h-[516px] bg-cover bg-center hidden md:block" style={{ backgroundImage: `url(${gRight})` }}></div>
      <div className="absolute bottom-0 left-0 w-[467px] h-[516px] bg-cover bg-center hidden md:block" style={{ backgroundImage: `url(${gRight})` }}></div>

      <div className="max-w-containerSm md:max-w-container mx-auto md:h-[636px] grid grid-cols-1 md:grid-cols-2 gap-4 relative mt-[60px]">
            <a href='/services/pr'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service1}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite1}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("PR Xidməti")}</p>
            </div>
            </div>
            </a> 
            <a href='/services/marketing'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service2}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite2}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("Rəqəmsal marketinq")}</p>
            </div>
            </div>
            </a>
            <a href='/services/branding'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service3}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite3}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("Brendinq")}</p>
            </div>
            </div>
            </a>
            <a href='/services/media'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service4}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite4}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("Media")}</p>
            </div>
            </div>
            </a>
            <a href='/services/events'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service5}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite5}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("Tədbirlərin təşkili")}</p>
            </div>
            </div>
            </a>
            <a href='/services/print'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service6}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite6}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("Çap və poliqrafiya")}</p>
            </div>
            </div>
            </a>
            <a href='/services/training'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service7}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite7}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("PR və kommunikasiya təlimi")}</p>
            </div>
            </div>
            </a>
            <a href='/services/network'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service8}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite8}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("Netvörkinq")} </p>
            </div>
            </div>
            </a>
            <a href='/services/advertising'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service9}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite9}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("Reklam")}</p>
            </div>
            </div>
            </a>
            <a href='/services/design'>
            <div className='bg-[#1C1C1C] hover:bg-primeColor group transition-all duration-300 ease-in-out rounded-[16px] px-4 py-3 text-white box-border h-[64px] md:h-[123px] flex'>
            <div className='flex justify-center items-center'>
            <img className='h-[40px] md:h-[61px] group-hover:hidden' src={service10}></img>
            <img className='h-[40px] md:h-[61px] hidden group-hover:block' src={serviceWhite10}></img>
            </div>
            <div className='flex items-center text-[18px] md:text-[20px] font-semibold group-hover:text-white'>
            <p className='ml-4'>{t("Qrafik dizayn")} </p>
            </div>
            </div>
            </a>
           
      </div>
    </div>
   </div>

</>
  );
};

export default Services;
