import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import axios from "axios";
import { useParams } from "react-router-dom";
import Others from "./Others";
import './NewsDetail.css'
import { useTranslation } from "react-i18next";

export default function BlogDetail() {
  const [blogs, setBlogs] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const swiperRef = useRef(null); // Swiper referansı
  const [activeIndex, setActiveIndex] = useState(0); // Aktif slide index

      const { t, i18n } = useTranslation();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.gpr.az/gpr/news.php`,
        { withCredentials: false }
      );
      const filteredProducts = response.data.data.filter(blog => blog.id == id);
      setBlogs(filteredProducts[0]);
      console.log(response.data.data)
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Eğer `blogs` verisi yüklendiyse fotoğrafları kontrol et
  const slides = [];
  if (blogs) {
    for (let i = 1; i <= 10; i++) {
      const imgUrl = blogs[`image${i}`];
      if (imgUrl) {
        slides.push({ img: `https://www.gpr.az/gpr/${imgUrl}` });
      }
    }
  }

  // Dinamik olarak 3 fotoğraf göstermek için hesaplama
  const getThumbnails = () => {
    const len = slides.length;
    return [
      slides[(activeIndex - 1 + len) % len], // Soldaki fotoğraf
      slides[activeIndex], // Aktif fotoğraf
      slides[(activeIndex + 1) % len], // Sağdaki fotoğraf
    ];
  };

  if (isLoading) {
    return <div>Loading...</div>; // Veriler yüklenirken bir loading göstergesi
  }

  return (
    <>
      <div className="md:flex max-w-containerSm md:max-w-container mx-auto justify-between pt-[80px]">
        <div className="mx-auto mt-10 md:w-[408px]">
          {/* Slider */}
          <Swiper
            ref={swiperRef}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)} // Aktif index güncellemesi
            modules={[Autoplay, Pagination, Navigation]}
            className="main-slider"
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="relative">
                 <img
  className="w-full h-[354px] object-contain rounded-xl"
  src={slide.img}
  alt={`Slide ${index + 1}`}
/>

                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Thumbnail'lar */}
          <div className="flex justify-center mt-5 space-x-3">
            {getThumbnails().map((slide, index) => (
              <div
                key={index}
                className={`cursor-pointer border-2 ${
                  index === 1 ? "border-red-500" : "border-transparent "
                } rounded-lg overflow-hidden`}
                onClick={() =>
                  swiperRef.current.swiper.slideTo(
                    (activeIndex - 1 + index + slides.length) % slides.length
                  )
                }
              >
                <img
                  className="w-[183px] h-[129px] object-cover"
                  src={slide.img}
                  alt={`Thumbnail ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mx-auto mt-10 md:w-[785px] text-white">
          {/* Blog Başlık ve İçeriği */}
          <p className='text-[28px] md:text-[40px]  font-bold '>
          <span className="text2-gradient">{t(blogs.title)}</span> 
          </p>
          <p className="text-[16px] mt-[32px]" dangerouslySetInnerHTML={{ __html: t(blogs.text) }} /><br></br>
        </div>
      </div>
      <Others/> 
    </>
  );
}
 



























