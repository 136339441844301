import React from 'react'
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { project1, project2, project3 } from '../../../assets';

const projects = [
    { id: 1, image: project1, link: '/news/1' },
    { id: 2, image: project2, link: '/news/2' },
    { id: 3, image: project3, link: '/news/3' },
    { id: 4, image: project1, link: '/news/4' },
    { id: 5, image: project2, link: '/news/5' },
    { id: 6, image: project3, link: '/news/6' },
    { id: 7, image: project1, link: '/news/7' },
  ];

const Others = () => {

      const { t, i18n } = useTranslation();



  return (
    <>
        <div className='bg-[#1C1C1C] py-[10px] mt-[40px] md:mt-[80px]'>
        <div className="max-w-container mx-auto mb-[100px]">
        <div className="flex justify-center items-center mx-auto mt-[60px]">
        </div>

        <p className="text-[28px] md:text-[40px] font-bold text-center text-gradient md:mb-10 mb-4">
          {t("Digər xəbərlər")}
        </p>
        <div className="relative">
          <Swiper
            effect={'coverflow'} 
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={3}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            breakpoints={{
              0: { slidesPerView: 1 }, // Mobil ekranlar (640px altı)
              640: { slidesPerView: 2 }, // Tablet boyutları
              1024: { slidesPerView: 3 }, // Büyük ekranlar
            }}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            {projects.map((project) => (
              <SwiperSlide key={project.id}>
                <a href={project.link} target="_blank" rel="noopener noreferrer">
                  <img src={project.image} alt={`slide_image_${project.id}`} />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* Navigation buttons */}
          <div className="swiper-button-prev absolute top-1/2 left-0 z-10 -translate-y-1/2 cursor-pointer text-gradient">
       
          </div>
          <div className="swiper-button-next absolute top-1/2 right-0 z-10 -translate-y-1/2 cursor-pointer text-gradient">
         
          </div>
        </div>
      </div>
        </div>
    </>
  )
}

export default Others