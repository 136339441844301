import { useEffect, useRef, useState } from "react";
import { contact, logo, service1, values1, values2, values3, values4, values5, values6, why1, why2, why3, why4, why5, whyus } from "../../assets";
import { useTranslation } from "react-i18next";
 
const About = () => {

  const { t, i18n } = useTranslation();

  const [inView, setInView] = useState(false);
  const awardsRef = useRef(null); 

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) { 
          setInView(true);
          observer.disconnect(); // Animasyon bir kez başladığında daha fazla gözlemleme gerek yok
        }
      },
      { threshold: 0.3 } // Komponentin %30'u görünür hale geldiğinde başlar 
    );

    if (awardsRef.current) {
      observer.observe(awardsRef.current);
    }

    return () => {
      if (awardsRef.current) {
        observer.unobserve(awardsRef.current);
      }
    };
  }, []); 
  return (
   <div className="pt-[79px]">
     <div className='max-w-containerSm md:max-w-container mx-auto mt-[20px] md:mt-[52px] relative'>
      <div className='absolute inset-0 bg-gradiet-1 blur-300px'></div>
      <div className='md:flex gap-8 text-white relative'>
        <div className='flex flex-col justify-center mt-5 md:mt-0'>
        <div className="flex items-center">
  {/* Dekoratif Duvar */}
  <div className="w-[2px] h-[20px] bg-[#E0B497] mb-4 mt-4"></div>
  {/* Metin */}
  <p className="md:text-[18px] text-[16px] font-semibold text-center text-[#E0B497] ml-[5px]">
  {t("Haqqımızda")}
  </p> 
</div>
<p className="md:mb-8 mb-9 text-[18px] md:text-[24px] font-semibold italic">{t("GPR-Müasir PR tərziniz!")}</p>
          <p className='md:mb-16 mb-9 text-[18px] md:text-[24px] font-semibold max-w-[1121px]'>
          <span className="text2-gradient">{t("GPR")}</span> {t("şirkəti 20 ildən artıq təcrübə ilə sizlərə ictimaiyyətlə əlaqələr, ənənəvi marketinq, rəqəmsal marketinq, qrafik dizayn, media, reklam, çap və poliqrafiya, PR və kommunikasiya təlimi, tədbirlərin təşkili və netvörkinq sahələri üzrə peşəkar xidmətlər təklif edir.")}
          <br></br><br></br><span className="text2-gradient">{t("Məqsədimiz")}</span> {t("şirkət və ya brendinizin cəmiyyətdə tanınması, nüfuzun formalaşdırılması, PR və Marketinq siyasətinin uğurla həyata keçirilməsi nəticəsində biznesinizin inkişafına nail olmaqdır.")} 
          <br></br><br></br><span className="text2-gradient">{t("Missiyamız")}</span> {t("inkişafınıza təkan vermək, şirkətinizi tanıtmaq, davamlı əlaqələr quran müstəsna kommunikasiya strategiyaları təqdim etməklə brendlərinizi, şirkətinizi yüksəltməkdir. Doğru strategiya, zövqlü yaradıcılıq və dəqiq icraya əsaslanaraq istər məhsulunuzun təqdimatı, istər reputasiyanızın idarə edilməsi, istərsə də hərtərəfli PR kampaniyası ilə, bizim xüsusi həllərimiz mesajınızın lazımi anda doğru auditoriyaya çatdırılmasını təmin edir.")}
          </p>
        </div> 
      </div>
    </div>
    <div className='max-w-containerSm md:max-w-container mt-[35px] md:mt-[51px] mx-auto'>
        <div className='flex flex-col justify-center md:mt-0'>
          <p className='text-[28px] md:text-[40px] font-bold text-center'>
          <span className="text2-gradient">{t("Niyə biz?")}</span> 
          </p>
         <div className="md:flex justify-between mt-10 md:mt-[60px]">
          <div className="md:w-[594px]">
          <ul className='text-[#FBFBFB] text-[16px] space-y-4 '>
  <div className="flex gap-5 items-center">
    <div className="flex w-8 h-8">
    <img className="w-8 h-8" src={why1}></img>
    </div>
  <p className="flex-1">{t("Güclü media əlaqələri, nüfuzlu media orqanları, xəbər portalları və kütləvi informasiya vasitəri ilə uğurlu əməkdaşlığımız mesajınızın ən təsirli kanallar vasitəsilə düzgün auditoriyaya çatmasına zəmanətdir!")}</p>
  </div>
  <div className="flex gap-5 items-center">
    <div className="flex w-8 h-8">
    <img className="w-8 h-8" src={why2}></img>
    </div>
  <p className="flex-1">{t("Hər bir brendin unikal olduğunu düşünür, şirkətinizə özəl unikal PR&Marketinq strategiyası hazırlayırıq.")}</p>
  </div> 
  <div className="flex gap-5 items-center">
    <div className="flex w-8 h-8">
    <img className="w-8 h-8" src={why3}></img>
    </div>
  <p className="flex-1">{t("GPR şirkəti nəticələrə əsaslanır. Ölçüləbilən nəticələrə nail olmaq üçün məlumatı vizual ilə birləşdiririk.")}</p>
  </div>
  <div className="flex gap-5 items-center">
    <div className="flex w-8 h-8">
    <img className="w-10 h-8" src={why4}></img>
    </div>
  <p className="flex-1">{t("Biz hər bir müştəri münasibətinə tərəfdaşlıq kimi baxaraq brendinizin səsini ucaltmaq üçün sıx əməkdaşlıq edirik.")}</p>
  </div>
  <div className="flex gap-5 items-center">
    <div className="flex w-8 h-8">
    <img className="w-7 h-8" src={why5}></img>
    </div>
  <p className="flex-1">{t("Brendinizi və ya şirkətinizi tanıtmaq, auditoriyaya nüfuz edərək uğurlu nəticələr əldə etmək üçün düzgün PR tərəfdaşını seçmək vacibdir. Bizə güvənə bilərsiniz!")}</p>
  </div>
</ul>
            </div>
          <div className="md:w-[546px] mt-5 md:mt-0">
             <img src={whyus}></img>
            </div>
         </div> 
        </div>
      </div>
      <div className='max-w-containerSm md:max-w-container mt-[44px] md:mt-[80px] mx-auto'>
        <div className='flex flex-col justify-center mt-5 md:mt-0'>
          <p className='md:mb-6 mb-4 text-[28px] md:text-[40px]  font-bold text-center'>
          <span className="text2-gradient">{t("Dəyərlərimiz")}</span> 
          </p>
         <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-[40px]">
<div className="flex ">
  <img className="w-[60px] h-[60px]" src={values1}></img>
  <div className="ml-5 md:w-[312px]"><p className="text-[white] font-semibold text-[18px] md:text-[24px]">{t("Peşəkarlıq")}</p> <p className="text-[#A7A7A7] md:text-[18px]">{t("İşimizdə hər zaman yüksək peşəkarlıq nümayiş etdiririk.")}</p></div>
  <div className="h-[88px] w-[1px] bg-primeColor hidden md:block"></div>
  </div>
  <div className="flex ">
  <img className="w-[60px] h-[60px]" src={values2}></img>
  <div className="ml-5 md:w-[312px]"><p className="text-[white] font-semibold text-[18px] md:text-[24px]">{t("İnnovasiya və inkişaf")}</p> <p className="text-[#A7A7A7] md:text-[18px]">{t("Yeni ideyalar və texnologiyaların tətbiqinə açığıq.")}</p></div>
  <div className="h-[88px] w-[1px] bg-primeColor hidden md:block"></div>
  </div>
  <div className="flex ">
  <img className="w-[60px] h-[60px]" src={values3}></img>
  <div className="ml-5"><p className="text-[white] font-semibold text-[18px] md:text-[24px]">{t("Müştəri yönümlülük")}</p> <p className="text-[#A7A7A7] md:text-[18px]">{t("Müştəri məmnuniyyəti və ehtiyacları prioritetimizdir.")}</p></div>
  </div>
         </div> 
         <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-9">
 <div className="flex ">
  <img className="w-[60px] h-[60px]" src={values4}></img>
  <div className="ml-5 md:w-[524px]"><p className="text-[white] font-semibold text-[18px]  md:text-[24px]">{t("Empatiya və etik davranış")}</p> <p className="text-[#A7A7A7] md:text-[18px]">{t("Hər bir müştərimizin fikrinə və fərqliliklərinə hörmətlə yanaşırıq.")}</p></div>
  <div className="h-[88px] w-[1px] bg-primeColor hidden md:block"></div>
  </div>
  <div className="flex ">
  <img className="w-[60px] h-[60px]" src={values5}></img>
  <div className="ml-5 md:w-[524px]"><p className="text-[white] font-semibold text-[18px] md:text-[24px]">{t("Məsuliyyətlilik")}</p> <p className="text-[#A7A7A7] md:text-[18px]">{t("Layihələrin icra tarixlərinə vaxtında əməl olunmasına, prosesin gedişatına və nəticələrə cavabdehik.")}</p></div>
  </div>
 </div>
        </div>
      </div>


      <div
      ref={awardsRef}
      style={{
        backgroundImage: `url(${contact})`,
        backgroundSize: 'cover', // Görüntüyü kapsayıcıya yayar
        backgroundPosition: 'center', // Görüntüyü ortalar
        backgroundRepeat: 'no-repeat', // Görüntünün tekrarlanmamasını sağlar
        height: window.innerWidth <= 640 ? '200px' : '360px', // Mobilde height 200px
        marginTop:'80px'
      }}
      className="bg-[#0C223E] text-white text-center flex flex-col justify-center"
    >
      <div className="max-w-containerSm md:max-w-container mx-auto md:flex w-full ">
        <p className="md:w-[644px] text-left mr-[14px] font-semibold text-[18px] md:text-[32px] flex  items-center">
          {t("Effektiv həllərlə")} <br></br> {t("etibarlı tərəfdaşlıq")}
        </p>
       <div className="flex mt-6 md:mt-0">
       <div className="text-left w-[134px] ">
          <p className='text-[16px] md:text-[32px] font-semibold'>20+</p> <br />
          <p className='text-[16px] md:text-[18px]'>{t("İllik təcrübə")}</p>
        </div>
       <div className='flex justify-center items-center mx-[28px]'>
       <div className='bg-[white] h-[32px] w-[2px] '></div>
       </div>
       <div className="text-left w-[159px] ">
          <p className='text-[16px] md:text-[32px] font-semibold'>135+</p> <br />
          <p className='text-[16px] md:text-[18px]'>{t("Məmnun müştəri")}</p>
        </div>
        <div className='flex justify-center items-center mx-[28px]'>
       <div className='bg-[white] h-[32px] w-[2px] '></div>
       </div>
       <div className="text-left w-[121px] ">
          <p className='text-[16px] md:text-[32px] font-semibold'>110+</p> <br />
          <p className='text-[16px] md:text-[18px]'>{t("Uğurlu layihə")}</p>
        </div>
       </div>
      </div>
    </div>
    </div>
  );
};

export default About;
