import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service4, services1 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const Media = () => {

     const { t, i18n } = useTranslation();


  return (
    <>
     <div className='pt-[79px]'>
<div
  className="relative h-[203px] md:h-[339px]"
  style={{
    backgroundImage: `url(${services1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  {/* Metin kutusu */}
  <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
    <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
    {t("Media xidməti")}
    </div>
  </div>
</div>


<div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
        <div>
        <p className='text-[#FBFBFB] font-medium'>{t("Media xidməti vasitəsilə haqqınızda press relizlər, məqalələr, audio və videolar, podkastlar, xəbərlər və digər multimedia materialları hazırlanaraq TV və radio kanallarında, ənənəvi və sosial mediada yayımlanır. Bu xidmətdən istifadə, əsasən, şirkətinizin və brendinizin tanıdılması, ictimai auditoriya ilə əlaqələrin qurulması və möhkəmləndirilməsi, eyni zamanda  müasir rəqəmsal platformalarda mövcudluğun artırılması məqsədi ilə həyata keçirilir. Əlavə olaraq, media xidməti dövlət, özəl və ya qeyri-kommersiya təşkilatları üçün xüsusi strategiyalar üzrə təklif olunur.")}</p>
        <div className='flex items-center mt-8'>
          <img className='h-8 w-8' src={service4}></img>
          <p className='text-[#FBFBFB] ml-3'>{t("Media xidmətinə daxildir:")}</p>
        </div>
        <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
          <div className='flex'>
            <div className='flex mt-2 mr-2'> 
            <img className='w-3 h-3' src={listIcon}></img>
            </div>
            <p className='flex-1'>{t("Reklamlar, audio və videolar, podkastlar, xəbərlər və digər multimedia materialları;")}</p>
          </div>
          <div className='flex'>
            <div className='flex mt-2 mr-2'>
            <img className='w-3 h-3' src={listIcon}></img>
            </div>
            <p className='flex-1'>{t("Məqalələr, press relizlərin yazılması və media resursları ilə informasiya mübadiləsi;")}</p>
          </div>
          <div className='flex'>
            <div className='flex mt-2 mr-2'>
            <img className='w-3 h-3' src={listIcon}></img>
            </div>
            <p className='flex-1'>{t("Televiziya, radio, internet və sosial media vasitəsilə məlumatların geniş auditoriyaya çatdırılması;")}</p>
          </div>
          <div className='flex'>
            <div className='flex mt-2 mr-2'>
            <img className='w-3 h-3' src={listIcon}></img>
            </div>
            <p className='flex-1'>{t("Şirkətinizin kütləvi informasiya vasitələri ilə kommunikasiyasının qurulması;")}</p>
          </div>
          <div className='flex'>
            <div className='flex mt-2 mr-2'>
            <img className='w-3 h-3' src={listIcon}></img>
            </div>
            <p className='flex-1'>{t("Media orqanlarında şirkətinizin nüfuzunun formalaşdırılması.")}</p>
          </div>

</ul>

        </div>
      </div>
     </div>
    </>
  );
};

export default Media;