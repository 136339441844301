import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "../src/Pages/Home/Home";
import About from "./Pages/About/About";
import AboutSosial from "./Pages/About/AboutSosial/AboutSosial";
import Recycling from "./Pages/About/Recycling/Recycling";
import Ecosystem from "./Pages/About/Ecosystem/Ecosystem";
import Organic from "./Pages/About/Organic/Organic";
import Biology from "./Pages/About/Biology/Biology";
import Services from "./Pages/Services/Services";
import Pr from "./Pages/Services/Pr/Pr";
import Marketing from "./Pages/Services/Marketing/Marketing";
import Branding from "./Pages/Services/Branding/Branding";
import Media from "./Pages/Services/Media/Media";
import Events from "./Pages/Services/Events/Events";
import Print from "./Pages/Services/Print/Print";
import Training from "./Pages/Services/Training/Training";
import Network from "./Pages/Services/Network/Network";
import NewsDetail from "./Pages/News/NewsDetail/NewsDetail";
import Contact from "./Pages/Contact/Contact";
import Career from "./Pages/Career/Career";
import CareerJoin from "./Pages/Career/CareerJoin/CareerJoin";
import CareerJoinProfessionals from "./Pages/Career/CareerJoinProfessionals/CareerJoinProfessionals";
import CareerJoinYouths from "./Pages/Career/CareerJoinYouths/CareerJoinYouths";
import CareerLife from "./Pages/Career/CareerLife/CareerLife";
import CareerRules from "./Pages/Career/CareerRules/CareerRules";
import CareerJobs from "./Pages/Career/CareerJobs/CareerJobs";
import News from "./Pages/News/News";
import Partners from "./Pages/Partners/Partners";
import Advertising from "./Pages/Services/Advertising/Advertising";
import Design from "./Pages/Services/Design/Design";
import JobsDetail from "./Pages/Career/JobsDetail/JobsDetail";
import JobsDetailApplication from "./Pages/Career/JobsDetailApplication/JobsDetailApplication";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const Layout = () => {
  return ( 
    <div>
       <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover 
        theme="colored"
      /> 
      <Header/>
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};
 
const router = createBrowserRouter(               
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/sosial" element={<AboutSosial />}></Route>
        <Route path="/sosial/recycling" element={<Recycling />}></Route>
        <Route path="/sosial/ecosystem" element={<Ecosystem />}></Route>
        <Route path="/sosial/organic" element={<Organic />}></Route>
        <Route path="/sosial/biology" element={<Biology />}></Route>
        <Route path="/services" element={<Services />}></Route>
        <Route path="/services/pr" element={<Pr />}></Route>
        <Route path="/services/marketing" element={<Marketing />}></Route>
        <Route path="/services/branding" element={<Branding />}></Route>
        <Route path="/services/media" element={<Media />}></Route>
        <Route path="/services/events" element={<Events />}></Route>
        <Route path="/services/print" element={<Print />}></Route>
        <Route path="/services/training" element={<Training />}></Route>
        <Route path="/services/network" element={<Network />} />
        <Route path="/services/design" element={<Design />} />
        <Route path="/services/advertising" element={<Advertising />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career/join" element={<CareerJoin />} />
        <Route path="/career/join/professionals" element={<CareerJoinProfessionals />} />
        <Route path="/career/join/youths" element={<CareerJoinYouths />} />
        <Route path="/career/life" element={<CareerLife />} />
        <Route path="/career/rules" element={<CareerRules />} />
        <Route path="/career/jobs" element={<CareerJobs />} />
        <Route path="/career/job-detail/:id" element={<JobsDetail />} />
        <Route path="/career/job-detail/:id/apply" element={<JobsDetailApplication />} />
        <Route path="/contact" element={<Contact />} />
      </Route> 
    </Route>
  )
);

function App() {

  return ( 
    <div className="">
      <div className="font-bodyFont bg-[#000000]">
      <div>
    <FloatingWhatsApp phoneNumber='+994102144814' accountName="GPR" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar2.jpg' messageDelay='1' />
      <RouterProvider router={router} />
      </div>
    </div>
    </div>
  );
}

export default App;