import axios from 'axios';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const JobsDetailApplication = () => {

      const navigate = useNavigate()

        const { t, i18n } = useTranslation();

      const [clientName, setclientName] = useState("");
      const [clientEmail, setclientEmail] = useState("");
      const [clientPhone, setclientPhone] = useState("");
      const [clientExtra, setclientExtra] = useState("");
        const [list, setItems] = useState([]);

        const handleSubmitOrder = async () => {
      
    
          let inputs = {name:  clientName, email: clientEmail, phone: clientPhone, address: clientExtra, product: list.name}
          try {
            // Seçilen ürünleri post isteği ile gönder
            axios.post("https://www.gpr.az/gpr/orders.php/", inputs)
            .then(function (response) { 
              navigate("/");  
              setclientName('')
              setclientEmail('')
              setclientPhone('')
              setclientExtra('')
      
              // console.log(inputs);
            });
            // Başarılı olduğunda yapılacak işlemler
            toast.success("Sifarişiniz qeydə alındı");
          } catch (error) {
            // Hata durumunda yapılacak işlemler
            console.error("Sipariş gönderilirken bir hata oluştu:", error);
          }
        };
    
  return (
             <>
             <div className='max-w-containerSm md:max-w-[643px] mx-auto pt-[119px]'>
              <form onSubmit={handleSubmitOrder}>
              <h2 className="text-[28px] md:text-[40px] font-bold text-[white] text-center md:mb-[40px] mb-[20px]">{t("Müraciət formu")}</h2>
                <div className="mb-4">
                  <label className="block text-[white]">{t("Ad, soyad")}*</label>
                  <input type="text" className="rounded-[12px] w-full h-[40px] pl-3 bg-[#1C1C1C] text-white focus:outline" 
                   placeholder="Lorem ipsum"
                   onChange={(event) => setclientName(event.target.value)}
                   value={clientName}
                   required
                    />
                   
                </div>
                <div className="mb-4">
                  <label className="block text-[white]">{t("E-mail")}*</label>
                  <input type="email" className="rounded-[12px] w-full h-[40px] pl-3 bg-[#1C1C1C] text-white focus:outline"  
                  placeholder="email@gmail.com"
                  onChange={(event) => setclientEmail(event.target.value)}
                  value={clientEmail}
                  required /> 
                </div>
                <div className="mb-4">
                  <label className="block text-[white]">{t("Əlaqə nömrəsi")}*</label>
                  <input type="number" className="rounded-[12px] w-full h-[40px] pl-3 bg-[#1C1C1C] text-white focus:outline" 
                  onChange={(event) => setclientPhone(event.target.value)}
                  value={clientPhone}
                  required /> 
                </div>
                <div className="mb-4">
                  <label className="block text-[white]">CV*</label>
                  <input  type="file" 
                  value={clientExtra}
                  required /> 
                </div>
                <div className="mb-4">
  <label className="block text-[white]">{t("Əlavə qeydiniz")}*</label>
  <textarea 
    className="rounded-[12px] w-full h-[100px] pl-3 pt-2 bg-[#1C1C1C] text-white resize-none focus:outline"  
    placeholder={t("Bizə yazın...")}
    onChange={(event) => setclientExtra(event.target.value)}
    value={clientExtra}
    required 
  />
</div>

                
                <div className="flex">
                  <button  type="submit" className="h-[40px] w-[149px] bg-primeColor text-white rounded-[20px] ">{t("Müraciət et")}</button>
                </div>
              </form>
             </div>
              </>
  )
}

export default JobsDetailApplication