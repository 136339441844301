import React, { useEffect, useRef, useState } from "react";
import { project1, project2, project3 } from "../../assets/index";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams } from "react-router-dom";

const News = () => {

        const { t, i18n } = useTranslation();

        
        const [blogs, setBlogs] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
      
        useEffect(() => {
          getProducts();
        }, []);
      
        const getProducts = async () => {
          try {
            const response = await axios.get(
              `https://www.gpr.az/gpr/news.php`,
              { withCredentials: false }
            );
            setBlogs(response.data.data);
            console.log(response.data.data)
            setIsLoading(false);
          } catch (error) {
            console.error("Error fetching products:", error);
          }
        };


  return (
    <>
      <div className="pt-[79px]">
        <div className="mx-auto mt-[20px] md:mt-[52px] relative">
          <div className="flex justify-center items-center mx-auto">
            <div className="w-[2px] h-[20px] bg-[#E0B497] mb-4 mt-4"></div>
            <p className="text-[16px] font-medium text-center text-[#E0B497] ml-[5px]">
              {t("Xəbərlər")}
            </p>
          </div>

          <p className='text-[28px] md:text-[40px]  font-bold text-center '>
          <span className="text2-gradient">{t("Xəbərlərlə tanış olun")}</span> 
          </p>


          <div className="max-w-containerSm md:max-w-container mx-auto mt-[44px] md:mt-[60px]">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {blogs?.map((project) => (
               <a key={project.id} href={`/news/${project.id}`} className="relative group">
               <div className="relative md:w-[400px]">
                 <img
                   className="rounded-[20px] md:w-[397px] md:h-[365px] mb-4 drop-shadow-[0px_10px_20px_rgba(0,0,0,0.3)]"
                   src={`https://www.gpr.az/gpr/${project.image1}`}
                   alt={`Project ${project.id}`}
                 />
                 <div className="absolute inset-0 bg-[#91303F] opacity-0 group-hover:opacity-40 transition-opacity duration-300 rounded-[20px]"></div>
                 
                 {/* Başlık İçin Yeni Efekt */}
                 <div className="absolute bottom-0 left-0 w-full p-4 rounded-b-[20px] bg-gradient-to-t from-black/80 to-transparent">
                   <p className="text-white text-lg font-bold drop-shadow-[0px_3px_5px_rgba(0,0,0,0.8)]">
                     {t(project.title)}
                   </p>
                 </div>
               </div>
             </a>
             
             
              
              ))}
            </div>

            <div className="mt-5 mx-auto flex justify-center">
              <a href="/projects">
                <p className="text-black text-base font-normal inline-block border-b border-black">
                  {t("Hamısını gör")}
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
