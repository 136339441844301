import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service6, service7, services7 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const Training = () => {

     const { t, i18n } = useTranslation();

  return (
    <>
     <div className='pt-[79px]'>
<div
      className="relative h-[203px] md:h-[339px]"
      style={{
        backgroundImage: `url(${services7})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Metin kutusu */}
      <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
        <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
        {t("PR və kommunikasiya təlimi")}
        </div>
      </div>
    </div>


       <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
                                       <div>
                                       <p className='text-[#FBFBFB] font-medium'>{t("PR və kommunikasiya təlimi, effektiv ünsiyyət, pozitiv kommunikasiyanın qurulması, marka imici, media əlaqələri və ictimaiyyətlə münasibətlərin idarə edilməsini öyrətmək məqsədi daşıyan təlim növüdür.")}</p>
                                       <div className='flex items-center mt-8'>
                                         <img className='h-8 w-8' src={service7}></img>
                                         <p className='text-[#FBFBFB] ml-3'>{t("Təlim mövzularına daxildir:")}</p>
                                       </div>
                                       <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                                         <div className='flex'>
                                           <div className='flex mt-2 mr-2'>
                                           <img className='w-3 h-3' src={listIcon}></img>
                                           </div>
                                           <p className='flex-1'>{t("PR və kommunikasiyanın əsasları (PR nədir və rolu; effektiv kommunikasiya strategiyaları; brend imicin yaradılması və idarə olunması və s.);")}</p>
                                         </div>
                                         <div className='flex'>
                                           <div className='flex mt-2 mr-2'>
                                           <img className='w-3 h-3' src={listIcon}></img>
                                           </div>
                                           <p className='flex-1'>{t("Media əlaqələri və mediada görünürlük (mətbuat üçün press-relizlərin yazılması qaydası, jurnalistlərlə münasibətlərin qurulması, mətbuatla əlaqələrin idarə olunmasının əsasları və s.);")}</p>
                                         </div>
                                         <div className='flex'>
                                           <div className='flex mt-2 mr-2'>
                                           <img className='w-3 h-3' src={listIcon}></img>
                                           </div>
                                           <p className='flex-1'>{t("Böhran kommunikasiyası (böhran zamanı mesajların idarə olunması, mənfi xəbərlərin minimallaşdırılması, ictimai etibarın bərpası üsulları və s.);")}</p>
                                         </div>
                                         <div className='flex'>
                                           <div className='flex mt-2 mr-2'>
                                           <img className='w-3 h-3' src={listIcon}></img>
                                           </div>
                                           <p className='flex-1'>{t("Rəqəmsal PR və Sosial Media (sosial mediada brendin idarə edilməsi, kampaniyaların hazırlanması, məzmun marketinqi və s.);")}</p>
                                         </div>
                                         <div className='flex'>
                                           <div className='flex mt-2 mr-2'>
                                           <img className='w-3 h-3' src={listIcon}></img>
                                           </div>
                                           <p className='flex-1'>{t("Kommunikasiya bacarıqları (auditoriyaya müraciət, nitq və təqdimat bacarıqlarının təkmilləşdirilməsi, bədən dili və jestlərin düzgün ifadəsi, dinləmə və əks əlaqə üsulları və s.);")}</p>
                                         </div>
                                         <div className='flex'>
                                           <div className='flex mt-2 mr-2'>
                                           <img className='w-3 h-3' src={listIcon}></img>
                                           </div>
                                           <p className='flex-1'>{t("PR və etika (PR-da peşəkar etik qaydalar, şəffafıq, doğruluq, maraqların təzadı və neytrallıq, ictimai etimadın qorunması, məlumatların məxfiliyi, manipulyasiya və dezinformasiya və s.).")}</p>
                                         </div>

                                
                               </ul>
                               
                                       </div>
                                     </div>
     </div>
    </>
  );
};

export default Training;

