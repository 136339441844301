import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service7, service8, services8 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const Network = () => {

     const { t, i18n } = useTranslation();

  return (
    <>
     <div className='pt-[79px]'>
      <div
            className="relative h-[203px] md:h-[339px]"
            style={{
              backgroundImage: `url(${services8})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {/* Metin kutusu */}
            <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
              <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
              {t("Netvörkinq")}
              </div>
            </div>
          </div>


      <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
                                            <div>
                                            <p className='text-[#FBFBFB] font-medium'>{t("Bu xidmət vasitəsilə şirkətinizin işgüzar əlaqələri genişləndirilir, peşəkar şəbəkələrdə şirkət və ya brendinizin təmsil olunmasına şərait yaradılır, müvafiq biznes platformaları ilə şirkətiniz arasında əlaqələrin qurulmasına dəstək verilir.")}</p>
                                            <div className='flex items-center mt-8'>
                                              <img className='h-8 w-8' src={service8}></img>
                                              <p className='text-[#FBFBFB] ml-3'>{t("Netvörkinq xidmətinə daxildir:")}</p>
                                            </div>
                                            <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                                              <div className='flex'>
                                                <div className='flex mt-2 mr-2'> 
                                                <img className='w-3 h-3' src={listIcon}></img>
                                                </div>
                                                <p className='flex-1'>{t("Peşəkar netvörkinq platformaları ilə işgüzar əlaqələrin qurulması;")}</p>
                                              </div>
                                              <div className='flex'>
                                                <div className='flex mt-2 mr-2'>
                                                <img className='w-3 h-3' src={listIcon}></img>
                                                </div>
                                                <p className='flex-1'>{t("Seminarlar, işgüzar görüşlər, konfranslar, biznes şəbəkələr və s.-də birbaşa ünsiyyət qurmaq imkanı;")}</p>
                                              </div>
                                            
                                              <div className='flex'>
                                                <div className='flex mt-2 mr-2'>
                                                <img className='w-3 h-3' src={listIcon}></img>
                                                </div>
                                                <p className='flex-1'>{t("Biznes şəbəkələşmə tədbirlərində bizneslər, sahibkarlar və təşkilatlar ilə əməkdaşlıq və ünsiyyət;")}</p>
                                              </div>
                                              <div className='flex'>
                                                <div className='flex mt-2 mr-2'>
                                                <img className='w-3 h-3' src={listIcon}></img>
                                                </div>
                                                <p className='flex-1'>{t("Sosial, ictimai, idman, xeyriyyə və s. prioritet sahələr üzrə tədbirlədə iştirak, şirkət və brendinizin tanıdılması.")}</p>
                                              </div>
     
                                    
                                    </ul>
                                    
                                            </div>
                                          </div>
     </div>
    </>
  );
};

export default Network;

