import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service5, services5 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const Events = () => {

     const { t, i18n } = useTranslation();

  return (
    <>
     <div className='pt-[79px]'>
<div
      className="relative h-[203px] md:h-[339px]"
      style={{
        backgroundImage: `url(${services5})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Metin kutusu */}
      <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
        <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
        {t("Tədbirlərin təşkili")}
        </div>
      </div>
    </div>


          <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
                           <div>
                           <p className='text-[#FBFBFB] font-medium'>{t("Bu xidmət müxtəlif növ tədbirlərin planlaşdırılması, təşkil və idarə olunmasını əhatə edir. Şirkətimiz korporativ (seminarlar, konfranslar, işgüzar görüşlər, təlimlər və s.) və ictimai ( festivallar, sərgilər, xeyriyyə və s.) tədbirlərin təşkilinə üstünlük verir.")}</p>
                           <div className='flex items-center mt-8'>
                             <img className='h-8 w-8' src={service5}></img>
                             <p className='text-[#FBFBFB] ml-3'>{t("Tədbirlərin təşkili xidmətinə daxildir:")}</p>
                           </div>
                           <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                             <div className='flex'>
                               <div className='flex mt-2 mr-2'>
                               <img className='w-3 h-3' src={listIcon}></img>
                               </div>
                               <p className='flex-1'>{t("Tədbirin konseptinin hazırlanması, mövzu seçimi və büdcə planlaması;")}</p>
                             </div>
                             <div className='flex'>
                               <div className='flex mt-2 mr-2'>
                               <img className='w-3 h-3' src={listIcon}></img>
                               </div>
                               <p className='flex-1'>{t("Tədbir məkanının seçimi və icarəsi;")}</p>
                             </div>
                             <div className='flex'>
                               <div className='flex mt-2 mr-2'>
                               <img className='w-3 h-3' src={listIcon}></img>
                               </div>
                               <p className='flex-1'>{t("Tədbir məkanının tərtibatı, dekorasiya elementlərinin hazırlanması;")}</p>
                             </div>
                             <div className='flex'>
                               <div className='flex mt-2 mr-2'>
                               <img className='w-3 h-3' src={listIcon}></img>
                               </div>
                               <p className='flex-1'>{t("Səs, işıqlandırma, video-foto çəkiliş xidmətləri;")}</p>
                             </div>
                             <div className='flex'>
                               <div className='flex mt-2 mr-2'>
                               <img className='w-3 h-3' src={listIcon}></img>
                               </div>
                               <p className='flex-1'>{t("Menyunun tərtibatı və furşetlər;")}</p>
                             </div>
                             <div className='flex'>
                               <div className='flex mt-2 mr-2'>
                               <img className='w-3 h-3' src={listIcon}></img>
                               </div>
                               <p className='flex-1'>{t("Aparıcı, DJ, canlı musiqi, animatorlar və digər əyləncə növlərinin təşkili;")}</p>
                             </div>
                             <div className='flex'>
                               <div className='flex mt-2 mr-2'>
                               <img className='w-3 h-3' src={listIcon}></img>
                               </div>
                               <p className='flex-1'>{t("Protokolların və ssenarilərin yazılması.")}</p>
                             </div>
                   
                   </ul>
                   
                           </div>
                         </div>
     </div>
    </>
  );
};

export default Events;

