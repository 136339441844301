import React, { useEffect, useState } from 'react';
import { project1, project2, project3 } from '../../../assets/index';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import axios from 'axios';



const News = () => {

  const { t, i18n } = useTranslation();

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.gpr.az/gpr/news.php`,
        { withCredentials: false }
      );
      setBlogs(response.data.data);
      console.log(response.data.data)
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };




  return (
    <>
      <div className="max-w-container mx-auto mb-[100px]">
        <div className="flex justify-center items-center mx-auto mt-[60px]">
          <div className="w-[2px] h-[20px] bg-[#E0B497] mb-4 mt-4"></div>
          <p className="text-[16px] font-medium text-center text-[#E0B497] ml-[5px]">
            {t("Xəbərlər")}
          </p>
        </div>

        <p className='text-[28px] md:text-[40px]  font-bold text-center md:mb-10 mb-4'>
          <span className="text2-gradient">{t("Xəbərlərlə tanış olun")}</span> 
          </p>
        <div className="relative">
          <Swiper
            effect={'coverflow'} 
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={3}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            breakpoints={{
              0: { slidesPerView: 1 }, // Mobil ekranlar (640px altı)
              640: { slidesPerView: 2 }, // Tablet boyutları
              1024: { slidesPerView: 3 }, // Büyük ekranlar
            }}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            {blogs.map((project) => (
              <SwiperSlide key={project.id}>
                <a href={project.link} target="_blank" rel="noopener noreferrer">
                  <img className='w-[397px] h-[365px] rounded-xl' src={`https://www.gpr.az/gpr/${project.image1}`} alt={`slide_image_${project.id}`} />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* Navigation buttons */}
          <div className="swiper-button-prev absolute top-1/2 left-0 z-10 -translate-y-1/2 cursor-pointer text-gradient">
            
          </div>
          <div className="swiper-button-next absolute top-1/2 right-0 z-10 -translate-y-1/2 cursor-pointer text-gradient">
            
          </div>
        </div>
        <div className='flex justify-center mt-6'>
        <a href='/news' className='text-[#e0b497] underline'>{t("Hamısını gör")}</a>
        </div>
      </div>
    </>
  );
};

export default News;
