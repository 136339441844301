import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { logo, logoHeader, logoOther, messageBlue, mpost, playCircle, search } from "../../assets/index";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import { NavLink, useLocation } from "react-router-dom";


  

const languageOptions = [
  { value: "en", label: "En",},
  { value: "az", label: "Az",},
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});


const Header = () => {
  const { t, i18n } = useTranslation();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);

  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showSubmenu2, setShowSubmenu2] = useState(false);
  const [showSubmenu3, setShowSubmenu3] = useState(false);

  


  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menuIndex) => {
    setActiveMenu(activeMenu === menuIndex ? null : menuIndex);
  };

  const location = useLocation();  
  const isActiveLink = (path) => {
    if (path === "/") {
      return location.pathname === "/"; // Ana sayfa için tam eşleşme
    }
    return location.pathname.startsWith(path); // Diğer yollar için başlangıç eşleşmesi
  };
 

  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  const toggleProductsSubMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };
 
  const toggleServicesSubMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);



  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };


  
 
  return (
    <>
       <nav className="w-full z-10 h-[79px] fixed bg-[black] ">
        <div className="md:max-w-container max-w-containerSm mx-auto">
          <div className="mx-auto flex items-center justify-between pt-[17px] md:pt-[10px]"> 
          <div>
              <a href='/'> <img src={logoHeader} alt="Logo" className="h-[44px] md:h-[60px]" /></a>
            </div> 
            <div className="hidden md:flex  w-[666px] h-[22px] justify-between items-center"> 
            <NavLink 
                to="/" 
                className={isActiveLink('/') ? 'bg-primeColor text-transparent bg-clip-text' : 'text-[#A7A7A7] text-base font-normal'}>{t("Əsas səhifə")}
              </NavLink>
              {/* <div
        className="relative inline-block"
        onMouseEnter={() => setShowSubmenu2(true)}
        onMouseLeave={() => setShowSubmenu2(false)}
      >
        <NavLink
          to="/about"
          className={isActiveLink('/about') ? 'bg-primeColor text-transparent bg-clip-text rounded-[20px] px-2' : 'text-[#A7A7A7] text-base font-normal'}>{t("Haqqımızda")}
        </NavLink>

        {showSubmenu2 && (
          <div className="absolute bg-[#020202] border border-[#1C1C1C] rounded-[12px] w-[202px]">
            <NavLink
              to="/about/sosial"
              className={isActiveLink('/about/sosial') ? 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C] hover:rounded-[12px]' : 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C] hover:rounded-[12px]'}>
              {t("Sosial layihələr")}
            </NavLink>
          </div>
        )}
      </div> */}
                    <NavLink 
                to="/about" 
                className={isActiveLink('/about') ? 'bg-primeColor text-transparent bg-clip-text rounded-[20px] px-2' : 'text-[#A7A7A7] text-base font-normal'}>
                {t("Haqqımızda")}
              </NavLink>
              <NavLink 
                to="/services" 
                className={isActiveLink('/services') ? 'bg-primeColor text-transparent bg-clip-text rounded-[20px] px-2' : 'text-[#A7A7A7] text-base font-normal'}>
                {t("Xidmətlər")}
              </NavLink>
              {/* <div
        className="relative inline-block"
        onMouseEnter={() => setShowSubmenu(true)}
        onMouseLeave={() => setShowSubmenu(false)}
      >
        <NavLink
          to="/projects"
          className={({ isActive }) =>
            isActive ? "text-[#f1b559]" : "text-black text-base font-normal"
          }
        >
          {t("LAYİHƏLƏRİMİZ")}
        </NavLink>

        {showSubmenu && (
          <div className="absolute bg-white shadow-lg w-[202px] rounded">
            <NavLink
              to="/projects/finished"
              className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
            >
              Bitmiş layihələr
            </NavLink>
            <NavLink
              to="/projects/unfinished"
              className="block px-4 py-2 text-black hover:bg-[#f1f1f1]"
            >
              Davam edən layihələr
            </NavLink>
          </div>
        )}
      </div> */}
                    <NavLink 
                to="/sosial" 
                className={isActiveLink('/sosial') ? 'bg-primeColor text-transparent bg-clip-text rounded-[20px] px-2' : 'text-[#A7A7A7] text-base font-normal'}>
                {t("Sosial layihələr")}
              </NavLink>
              <NavLink 
                to="/news" 
                className={isActiveLink('/news') ? 'bg-primeColor text-transparent bg-clip-text rounded-[20px] px-2' : 'text-[#A7A7A7] text-base font-normal'}>
                {t("Xəbərlər")}
              </NavLink>
              <div
        className="relative inline-block"
        onMouseEnter={() => setShowSubmenu3(true)}
        onMouseLeave={() => setShowSubmenu3(false)}
      >
        <NavLink
          to="/career/join"
          className={isActiveLink('/career/') ? 'bg-primeColor text-transparent bg-clip-text rounded-[20px] px-2' : 'text-[#A7A7A7] text-base font-normal'}>
          {t("Karyera")}
        </NavLink>

        {showSubmenu3 && (
          <div className="absolute bg-[#020202] border border-[#1F1F1F] rounded-[12px] w-[202px] ">
            <NavLink
              to="/career/join"
              className={isActiveLink('/career/') ? 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C] hover:rounded-t-[12px]' : 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C] hover:rounded-t-[12px]'}>
              {t("Bizə qoşulun")}
            </NavLink>
            <NavLink
              to="/career/life"
              className={isActiveLink('/career/') ? 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C]' : 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C]'}>
              {t("GPR-da iş və həyat")}
            </NavLink>
            <NavLink
              to="/career/rules"
              className={isActiveLink('/career/') ? 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C]' : 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C]'}>
              {t("İşə qəbul qaydaları")}
            </NavLink>
            <NavLink
              to="/career/jobs"
              className={isActiveLink('/career/') ? 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C] hover:rounded-b-[12px]' : 'block px-2 py-2 text-white text-[14px] font-normal hover:border hover:border-[#1C1C1C] hover:bg-[#1C1C1C] hover:rounded-b-[12px]'}>
              {t("Vakansiyalar")}
            </NavLink>
          </div>
        )}
      </div>
            </div>
            <div className="flex">
              {/* <a className="hidden md:inline" href="/contact">
                <button className="bg-[#f1b559] hover:bg-[#898989] w-[146px] rounded-lg h-10 text-[white]">
                  Layihəm var
                </button>
              </a> */}
              <div className="justify-center items-center hidden md:flex">
              <img className="h-6 w-6" src={search}></img>
              </div>
              <a className="hidden md:inline-block mx-5" href='/contact'> <button className='bg-primeColor text-[white] rounded-[20px] w-[148px] h-[40px] flex items-center justify-center'>
{t("Əlaqə")}
</button></a>
<div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <button
              onClick={toggleDropdown}
              className="flex items-center space-x-2 px-4 py-2 rounded-full text-white"
            >
              <span>{selectedLanguage.label}</span>
              <i class="fa-solid fa-chevron-down"></i>
            </button>

            {isOpen && (
              <div className="absolute right-0 top-10 mt-2 w-16 rounded-md shadow-lg bg-[black] text-white ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#1C1C1C]"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))} 
                </div>
              </div>
            )}
          </div>
          <button className="text-[black] focus:outline-none md:hidden" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[white] text-[24px]"></i>
      </button>

      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>

          <div className="flex flex-col items-start w-full">
            <div className=" items-center w-[100%] justify-center sm:justify-end h-screen bg-[black]">
              <div className="items-center h-[80px]  flex justify-between p-4">
              <div>
              <a href='/'> <img src={logoHeader} alt="Logo" className="h-[43px]" /></a>
            </div> 
            <div className="justify-center items-center flex">
              <img className="h-6 w-6" src={search}></img>
              <button className=" text-white p-2 rounded ml-2" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[white] text-[24px]"></i>
          </button>
              </div>
              </div>
            <div className="items-center gap-4 h-[300px] p-4"> 

                    {/* Button 3 */}
      <div className="w-full relative ">
      <NavLink 
                to="/" 
                onClick={toggleHamburgerMenu}
                end 
                className={({ isActive }) => isActive ? "bg-primeColor text-transparent bg-clip-text" : "text-[#A7A7A7] text-base font-normal"}
              >
                {t("Əsas səhifə")}
              </NavLink>
      </div>
      <div className="w-full relative ">
              <div className="w-full relative mt-2">
      <NavLink 
                to="/about" 
                onClick={toggleHamburgerMenu}
                end 
                className={({ isActive }) => isActive ? "bg-primeColor text-transparent bg-clip-text" : "text-[#A7A7A7] text-base font-normal"}
              >
                {t("Haqqımızda")}
              </NavLink>
              
        {/* <a href="/">
        <button
          onClick={() => toggleMenu('menu1')}
          className="w-full h-[50px] text-[white] rounded-lg text-left"
        >
          {t("Əsas səhifə")}
        </button>
        </a> */}
      </div>
      </div>
      <div className="w-full relative mt-2 ">
      <NavLink 
                to="/services" 
                onClick={toggleHamburgerMenu}
                end 
                className={({ isActive }) => isActive ? "bg-primeColor text-transparent bg-clip-text" : "text-[#A7A7A7] text-base font-normal"}
              >
                {t("Xidmətlər")}
              </NavLink>
            
      </div>
      <div className="w-full relative mt-2">
      <NavLink 
                to="/sosial" 
                onClick={toggleHamburgerMenu}
                end 
                className={({ isActive }) => isActive ? "bg-primeColor text-transparent bg-clip-text" : "text-[#A7A7A7] text-base font-normal"}
              >
                {t("Sosial layihələr")}
              </NavLink>
            
      </div>
      <div className="w-full relative mt-2">
      <NavLink 
                to="/news" 
                onClick={toggleHamburgerMenu}
                end 
                className={({ isActive }) => isActive ? "bg-primeColor text-transparent bg-clip-text" : "text-[#A7A7A7] text-base font-normal"}
              >
                {t("Xəbərlər")}
              </NavLink>

      </div>
      <div className="w-full relative ">
     <div className="flex items-center mt-2">
     <div className="w-[4px] h-[4px] bg-[#A7A7A7] rounded-full mr-1"></div>
      <NavLink 
                to="/career/join" 
                onClick={toggleHamburgerMenu}
                end 
                className={({ isActive }) => isActive ? "bg-primeColor text-transparent bg-clip-text" : "text-[#A7A7A7] text-base font-normal"}
              >
                {t("Karyera")}
              </NavLink>
              </div>
              <a 
  href="/career/join" 
  className="block px-2 py-1 rounded text-[#A7A7A7] text-[14px]"
>
  {t("Bizə qoşulun")}
</a>
<a 
  href="/career/life" 
  className="block px-2 py-1 rounded text-[#A7A7A7] text-[14px]"
>
  {t("GPR-də iş və həyat")}
</a>
<a 
  href="/career/rules" 
  className="block px-2 py-1 rounded text-[#A7A7A7] text-[14px]"
>
  {t("İşə qəbul qaydaları")}
</a>
<a 
  href="/career/jobs" 
  className="block px-2 py-1 rounded text-[#A7A7A7] text-[14px]"
>
  {t("Vakansiyalar")}
</a>
            
      </div>
      <div className="w-full relative ">
      <NavLink 
                to="/contact" 
                onClick={toggleHamburgerMenu}
                end 
                className={({ isActive }) => isActive ? "bg-primeColor text-transparent bg-clip-text" : "text-[#A7A7A7] text-base font-normal"}
              >
                {t("Əlaqə")}
              </NavLink>
              
       
      </div>
      
    

    </div>
            <div className="p-4">
           <a href="/contact"><p className="text-[#2C5AFF]"> </p></a>
            </div>

          </div>
          </div>
        </div>
      )}   
            </div>
          </div>
        </div>
      </nav>   
    </>
  ); 
};

export default Header;
