import React from 'react';
import { about, aboutSosial, biology, careerJoin, gprJobsApply, gprLifeBody, mission, rules1, rules2, rules3, rules4, service1 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const CareerRules = () => {

    const { t, i18n } = useTranslation();

  return (
    <>
     <div className='pt-[79px]'>
        <div
                               className="relative h-[203px] md:h-[339px]"
                               style={{
                                 backgroundImage: `url(${gprJobsApply})`,
                                 backgroundSize: 'cover',
                                 backgroundPosition: 'center',
                                 backgroundRepeat: 'no-repeat',
                               }}
                             >
                               {/* Metin kutusu */}
                               <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
                                 <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold ">
                                 {t("GPR-da işə qəbul qaydaları")} 
                                 </div>
                               </div>
                             </div>


        <div className="max-w-containerSm md:max-w-container mx-auto md:mt-[40px] mt-[32px]">
                           <div className='max-w-[1115px]'>
          <p className='text-[#FBFBFB] font-bold text-[24px] md:text-[40px]'>{t("Yeni iş, yeni imkanlar, yeni Sən!")}</p>
        <p className='text-[#FBFBFB] md:mt-[32px] mt-[20px]'>{t("GPR həm təcrübəli kadrlar, həm də məzunlar üçün geniş iş imkanları təklif edir. Uyğun mütəxəssisləri seçmək, onların potensialını düzgün qiymətləndirmək və güclü komanda yaratmaq GPR HR-ları üçün prioritetdir. Bu prosesdə şəffaflıq, professionallıq, vicdanlılıq və obyektivlik əsas götürülür.")}</p>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-5 mt-8'>
  <div className='md:w-[295px] w-full rounded-2xl bg-[#1C1C1C]'>
    <img className='h-[157px] w-full md:w-[295px]' src={rules1}></img>
    <div className='px-3 py-4'>
    <p className='text-[#FBFBFB]  font-bold'>{t("CV dəyərləndirilməsi")}</p>
    <p className='mt-3 text-[#FBFBFB]'>{t("Bu mərhələdə namizədlərin CV-ləri toplanılır, mövcud vakansiya tələblərinə görə uyğun CV-lər seçilir və namizədlərlə əlaqə saxlanılır.")}</p>
    </div>
  </div>
  <div className='md:w-[295px] w-full rounded-2xl bg-[#1C1C1C]'>
    <img className='h-[157px] w-full md:w-[295px]' src={rules2}></img>
    <div className='px-3 py-4'>
    <p className='text-[#FBFBFB]  font-bold'>{t("İlkin müsahibə")}</p>
    <p className='mt-3 text-[#FBFBFB]'>{t("Vakansiya tələblərinə uyğun namizədlərlə HR meneceri tərəfindən ilkin müsahibə aparılır.")}</p>
    </div>
  </div>
  <div className='md:w-[295px] w-full rounded-2xl bg-[#1C1C1C]'>
    <img className='h-[157px] w-full md:w-[295px]' src={rules3}></img>
    <div className='px-3 py-4'>
    <p className='text-[#FBFBFB]  font-bold'>{t("İkinci müsahibə")}</p>
    <p className='mt-3 text-[#FBFBFB]'>{t("İlkin müsahibədən uğurla keçmiş namizədlər şirkət rəhbərliyi ilə ikinci müsahibəyə dəvət olunurlar.")}</p>
    </div>
  </div>
  <div className='md:w-[295px] w-full rounded-2xl bg-[#1C1C1C]'>
    <img className='h-[157px] w-full md:w-[295px]' src={rules4}></img>
    <div className='px-3 py-4'>
    <p className='text-[#FBFBFB]  font-bold'>{t("İşə qəbul")} </p>
    <p className='mt-3 text-[#FBFBFB]'>{t("Hər iki mərhələdən uğurla keçən namizədlər qanunvericiliyin tələblərinə uyğun işə qəbul edilir və GPR komandasına təqdim olunur.")} </p>
    </div>
  </div>
  <div>

  </div>
</div>
           </div>
     </div>
    </>
  );
};

export default CareerRules;

