import { useTranslation } from "react-i18next";

const About = () => {

    const { t, i18n } = useTranslation();
  return (
    <div className='max-w-containerSm md:max-w-container mx-auto mt-[44px] md:mt-[80px] relative'>
     <div className="md:max-w-[1121px]">
     <div className='absolute inset-0 bg-gradiet-1 blur-300px'></div>
      <div className='md:flex gap-8 text-white relative '>
        <div className='flex flex-col justify-center mt-5 md:mt-0'>
        <div className="flex items-center">
  {/* Dekoratif Duvar */}
  <div className="w-[2px] h-[20px] bg-[#E0B497] mb-4 mt-4"></div>
  {/* Metin */}
  <p className="text-[16px] font-medium text-center text-[#E0B497] ml-[5px]">
  {t("Haqqımızda")}
  </p>
</div>
          <p className='md:mb-8 mb-5 font-semibold text-[18px] md:text-2xl'>
          {t("GPR şirkəti 20 ildən artıq təcrübə ilə sizlərə ictimaiyyətlə əlaqələr, ənənəvi marketinq, rəqəmsal marketinq, qrafik dizayn, media, reklam, çap və poliqrafiya, PR və kommunikasiya təlimi, tədbirlərin təşkili və netvörkinq sahələri üzrə peşəkar xidmətlər təklif edir.")}
          </p>
          <a href='/about'>
  <button className="gradient-border-button">
    {t("Daha çox")}
  </button>
</a>

 
        </div>
      </div>
     </div>
    </div>
  );
};

export default About;
