import React from 'react';
import { about, aboutSosial, biology, careerProfessionals, careerYouths, mission, service1 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const CareerJoinYouths = () => {

    const { t, i18n } = useTranslation();

  return (
    <>
        <div className='pt-[79px]'>
        <div
                       className="relative h-[203px] md:h-[339px]"
                       style={{
                         backgroundImage: `url(${careerYouths})`,
                         backgroundSize: 'cover',
                         backgroundPosition: 'center',
                         backgroundRepeat: 'no-repeat',
                       }}
                     >
                       {/* Metin kutusu */}
                       <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
                         <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold ">
                         {t("Gənclər Üçün Təcrübə Proqramları")}
                         </div>
                       </div>
                     </div>
   
   
         <div className="max-w-containerSm md:max-w-container mx-auto md:mt-[40px] mt-[32px]">
           <div className='max-w-[1010px]'>
             <p className='text-[#FBFBFB] font-bold text-[24px] md:text-[40px]'>{t("Karyeranızın ilk addımları")}</p>
           <p className='text-[#FBFBFB] md:mt-[32px] mt-[20px]'>{t("Təcrübə proqramları gənclərin iş həyatına daha tez inteqrasiya olmasına və seçdikləri sahədə ilkin təcrübə qazanmalarına imkan yaradan mühüm platformalardır. Bu proqramlar tələbələr və yeni məzunlar üçün karyera yollarını aydınlaşdırmaqla yanaşı, iş bazarına daxil olmağı asanlaşdırır.")} <br></br><br></br>

{t("GPR təcrübə proqramları çərçivəsində gənclər real iş təcrübəsinə yiyələnirlər. Bu onlara nəzəri bilikləri praktikada tətbiq etmək imkanı verir, sahəni daha yaxşı başa düşməsinə şərait yaradır. Təcrübə proqramları vasitəsilə gənclər müvafiq sahələr üzrə mütəxəssislərlə tanış olub, peşəkar əlaqələr qurur, məsuliyyət, komandada işləmək və ünsiyyət bacarıqlarını inkişaf etdirirlər.")} <br></br><br></br>

{t("Təcrübə proqramları gənclərin özlərini peşəkar dünyada tapmaları üçün əvəzsiz imkandır. Doğru təcrübə proqramını seçərək, onlar yalnız bilik və bacarıqlarını artırmaqla qalmayacaq, həm də öz karyera məqsədlərinə daha tez çatacaqlar. GPR gələcəyin istedadlı mütəxəssislərini yetişdirmək üçün bu proqramlara daha çox diqqət ayırır.")}</p>
   <a href='/career/jobs'>
               <button className='text-[white] bg-primeColor rounded-[20px] w-[148px] h-[40px] flex items-center justify-center mt-6'>
               {t("Müraciət et")}
               </button>
             </a>
           </div>
         </div>
        </div>
       </>
  );
};

export default CareerJoinYouths;

