import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service2, service3, services2, services3 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';

const Marketing = () => {

     const { t, i18n } = useTranslation();

  return (
    <>
     <div className='pt-[79px]'>
<div
  className="relative h-[203px] md:h-[339px]"
  style={{
    backgroundImage: `url(${services3})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  {/* Metin kutusu */}
  <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
    <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
    {t("Rəqəmsal marketinq")}
    </div>
  </div>
</div>


        <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
                <div>
                <p className='text-[#FBFBFB] font-medium'>{t("Rəqəmsal marketinq (digital marketing) xidməti vasitəsilə şirkətiniz və ya brendiniz onlayn mühitdə tanıdılır. Bu zaman ənənəvi marketinqdən fərqli olaraq rəqəmsal kanallar və alətlər əsas götürülür.")}</p>
                <div className='flex items-center mt-8'>
                  <img className='h-8 w-8' src={service2}></img>
                  <p className='text-[#FBFBFB] ml-3'>{t("Rəqəmsal marketinq xidmətinə daxildir:")}</p>
                </div>
                <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                  <div className='flex'>
                    <div className='flex mt-2 mr-2'>
                    <img className='w-3 h-3' src={listIcon}></img>
                    </div>
                    <p className='flex-1'>{t("Veb-saytların axtarış platformalarında daha yaxşı yer tutması üçün optimallaşdırma (SEO);")}</p>
                  </div>
                  <div className='flex'>
                    <div className='flex mt-2 mr-2'>
                    <img className='w-3 h-3' src={listIcon}></img>
                    </div>
                    <p className='flex-1'>{t("Rəqəmsal platformalarda ödənişli reklamlarla trafikin artırılması (SEM);")}</p>
                  </div>
                  <div className='flex'>
                    <div className='flex mt-2 mr-2'>
                    <img className='w-3 h-3' src={listIcon}></img>
                    </div>
                    <p className='flex-1'>{t("Linkedin, Instagram, Facebook və digər sosial şəbəkələrdə brendin tanıtımı (SMM);")}</p>
                  </div>
                  <div className='flex'>
                    <div className='flex mt-2 mr-2'>
                    <img className='w-3 h-3' src={listIcon}></img>
                    </div>
                    <p className='flex-1'>{t("Müştərilərlə əlaqə saxlamaq və şirkət və ya brendiniz barədə məlumat vermək üçün e-poçtların istifadəsi;")}</p>
                  </div>
                  <div className='flex'>
                    <div className='flex mt-2 mr-2'>
                    <img className='w-3 h-3' src={listIcon}></img>
                    </div>
                    <p className='flex-1'>{t("Bloq yazıları, videolar, infoqrafikalar və digər məzmun növləri ilə auditoriyanın cəlb edilməsi.")}</p>
                  </div>
        
        </ul>
        
                </div>
              </div>
     </div>
    </>
  );
};

export default Marketing;