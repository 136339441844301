import React from 'react';
import { facebook, facebookGradient, footerLogo, hero, instagram, instagramGradient, linkedin, linkedinGradient, logo, mail, mailGradient, playCircle, whatsapp, whatsappGradient} from '../../assets';
import { useTranslation } from 'react-i18next';


const Footer = () => {

  const { t, i18n } = useTranslation();
  return (
    <footer className="bg-[#131313] text-[white] md:mt-[220px] mt-[44px]">
      <div className="md:max-w-container max-w-containerSm mx-auto md:flex justify-between pt-[60px]">
        <div className='md:w-[247px] '>
        <a className='inline-block' href='/'><img className="h-[65px]" src={logo}></img></a>
          <p className='mt-[14px] italic'>{t("GPR-Müasir PR tərziniz!")}</p>
          <div className='flex gap-2 mt-[24px]'>
<a target='_blank' href='tel:+9940102144814'>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={whatsapp} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={whatsappGradient} alt="icon hover" />
</div> 
</a>
<a target='_blank' href='https://www.instagram.com/gpr.company/'>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={instagram} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={instagramGradient} alt="icon hover" />
</div>
</a>
<a  target='_blank' href='mailto:info@gpr.az'>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={mail} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={mailGradient} alt="icon hover" />
</div>
</a>
<a target='_blank' href='https://www.facebook.com/profile.php?id=61565031273402'>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={facebook} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={facebookGradient} alt="icon hover" />
</div>
</a>
<a target='_blank' href='https://www.linkedin.com/in/gpr-marketing-37a7a7325/'>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={linkedin} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={linkedinGradient} alt="icon hover" />
</div>
</a>
<a href='https://www.tiktok.com/@agrogurama?_t=8plWovrMOZw&_r=1'> 
</a>
</div>

        </div>
        <div className='w-[286px] mt-5 md:mt-0 '>  
          <h4 className="text-lg font-bold mb-2">{t("Sürətli keçidlər")}</h4>
          <ul className='md:flex w-[300px] justify-between mt-8' >
         <div>
            <li className='mb-2'><a href="/about" className="hover:underline  mb-2">{t("Haqqımızda")}</a></li>
            <li className='mb-2'><a href="/services" className="hover:underline  mb-2">{t("Xidmətlər")}</a></li>
            <li className='mb-2'><a href="/career/join" className="hover:underline  mb-2">{t("Karyera")}</a></li>
         </div>
<div>
<li className='mb-2'><a href="/sosial" className="hover:underline  mb-2">{t("Sosial layihələr")}</a></li>
<li className='mb-2'><a href="/news" className="hover:underline  mb-2">{t("Xəbərlər")}</a></li>
            <li className='mb-2'><a href="/contact" className="hover:underline  mb-2">{t("Əlaqə")}</a></li>
</div>
          </ul>
        </div>
        <div className='md:w-[352px] h-[188px] mt-4 md:mt-0 bg-[#292929] rounded-[20px] py-4 px-3'>

        <h4 className="text-lg font-semibold mb-4">{t("Təklifinizi yazın")}</h4>
        <textarea
    type="text"
    id="company"
    className="w-full h-10 p-2.5 bg-white rounded-xl border border-[#bcbcbc] justify-start items-center gap-2.5 inline-flex text-sm focus:outline-none text-black transition-all duration-300 resize-none overflow-hidden"
    placeholder={t("Bura qeyd edin...")}
    rows="1"
    maxLength={100} // Karakter sınırı burada
    onInput={(e) => {
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;

      // Karakter sınırı kontrolü
      if (e.target.value.length > 200) {
        e.target.value = e.target.value.slice(0, 200);
      }
    }}
  />
  <div class="flex justify-start mt-3">
      <button type="submit" class="w-full h-10 p-2.5 bg-primeColor rounded-xl justify-center items-center gap-2.5 inline-flex text-[white]">{t("GÖNDƏR")}</button>
    </div>



        </div>
      </div>
      <div className='h-[1px] bg-black mt-5'></div>
      <div className="md:max-w-[1260px] max-w-containerSm md:flex justify-between mt-3 md:mt-8 md:text-center text-sm mx-auto pb-5">
       <p> GPR.az © {t("Bütün hüquqlar qorunur.")}</p>
       <p> {t("Sayt hazırlandı")}: <a href='https://www.birsayt.az/'>Birsayt.az</a></p>
      </div>
    </footer>
  );
};

export default Footer;